import { Component, Input } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { DateRangeInputConfig } from '../input.type';

/**
 * Daterange input component.
 * @param format - The format of the date.
 * @param minDate - The minimum date.
 * @param maxDate - The maximum date.
 */
@Component({
    selector: 'app-daterange-input',
    templateUrl: './daterange-input.component.html'
})
export class DaterangeInputComponent extends BaseInputComponent<{ startDate: Date; endDate: Date }> {
    @Input() format: DateRangeInputConfig['format'] = 'dd. MM. yyyy';
    @Input() minDate: DateRangeInputConfig['minDate'];
    @Input() maxDate: DateRangeInputConfig['maxDate'];

    override validate(value: { startDate: Date; endDate: Date }) {
        const error: string[] = [];

        if (this.validateFn) {
            const validateResult = this.validateFn(value);
            if (validateResult) {
                error.push(validateResult);
            }
        }

        if (this.isRequired && (!value.startDate || !value.endDate)) {
            error.push(this.translationService.instant('This field is required'));
        }

        this.error.set(error);
    }
}
