import { Bollard, Pier, Port, Times } from './neo-scheduler.interface';

export function debounce<F extends (...args: Parameters<F>) => ReturnType<F>>(
    func: F,
    waitFor: number
): (...args: Parameters<F>) => void {
    let timeout: ReturnType<typeof setTimeout>;

    return (...args: Parameters<F>): void => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), waitFor);
    };
}

export function generateTimes(from: Date, to: Date, resolution: number): Times {
    const times: Times = [];
    const startDate = new Date(from);
    const endDate = new Date(to);
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        const day = {
            date: new Date(currentDate),
            hours: [] as { hour: number; minute: number }[]
        };

        const startHour = currentDate.getDate() === startDate.getDate() ? startDate.getHours() : 0;
        const endHour = currentDate.getDate() === endDate.getDate() ? endDate.getHours() : 23;

        for (let hour = startHour; hour <= endHour; hour++) {
            const startMinute =
                hour === startHour && currentDate.getDate() === startDate.getDate() ? startDate.getMinutes() : 0;
            const endMinute =
                hour === endHour && currentDate.getDate() === endDate.getDate() ? endDate.getMinutes() : 59;

            for (let minute = startMinute; minute <= endMinute; minute += resolution) {
                day.hours.push({ hour, minute });
            }
        }

        times.push(day);
        currentDate.setDate(currentDate.getDate() + 1);
        currentDate.setHours(0, 0, 0, 0); // Reset to start of the next day
    }

    return times;
}

export function flattenTimes(times: Times): { hour: number; minute: number }[] {
    return times.flatMap((day) => day.hours);
}

export function flattenBollards(piers: Pier[]): Bollard[] {
    return piers.flatMap((pier) => pier.berths.flatMap((berth) => berth.bollards));
}

export function generateRowProperties(port: Port, scale: number = 1): Port {
    const dividerRowHeight = 24;
    return {
        ...port,
        piers: port.piers.map((pier) => ({
            ...pier,
            berths: pier.berths.map((berth, berthIndex) => ({
                ...berth,
                bollards: berth.bollards.map((bollard, index, array) => {
                    let rowHeight: number;

                    if (bollard.final) {
                        rowHeight = dividerRowHeight * scale;
                    } else if (index === array.length - 1) {
                        // last bollard in the berth, but not final
                        rowHeight = pier.berths[berthIndex + 1].bollards[0].gap_to_previous * scale;
                    } else {
                        rowHeight = array[index + 1].gap_to_previous * scale;
                    }
                    return {
                        ...bollard,
                        rowHeight
                    };
                })
            }))
        }))
    };
}

export function enhanceBollardInfo(port: Port): Port {
    return {
        ...port,
        piers: port.piers.map((pier) => {
            const allBollards = pier.berths.flatMap((berth) => berth.bollards);
            return {
                ...pier,
                berths: pier.berths.map((berth) => ({
                    ...berth,
                    bollards: berth.bollards.map((bollard) => ({
                        ...bollard,
                        cargo_types: bollard.cargo_types || berth.cargo_types,
                        max_draft: bollard.max_draft || berth.max_draft,
                        // Set initial and final based on the bollard's position within the pier
                        initial: bollard.initial !== undefined ? bollard.initial : allBollards[0] === bollard,
                        final:
                            bollard.final !== undefined
                                ? bollard.final
                                : allBollards[allBollards.length - 1] === bollard
                    }))
                }))
            };
        })
    };
}

export function subtractFromArrayOfNumbers(array: number[], value: number): number[] {
    let remainingValue = value;
    return array.map((number) => {
        const [newNumber, remainder] = subtractToZeroAndReturnRemainder(number, remainingValue);
        remainingValue = remainder;
        return newNumber;
    });
}

export function subtractToZeroAndReturnRemainder(number: number, subtract: number): [number, number] {
    const newNumber = Math.max(0, number - subtract);
    const remainder = Math.max(0, subtract - number);
    return [newNumber, remainder];
}

export function dateDifferenceToDaysAndHoursAndMinutes(date1: Date, date2: Date): string {
    const diffInMs = date2.getTime() - date1.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const diffInHours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

    const parts: string[] = [];
    if (diffInDays > 0) parts.push(`${diffInDays} d`);
    if (diffInHours > 0) parts.push(`${diffInHours} h`);
    if (diffInMinutes > 0) parts.push(`${diffInMinutes} m`);

    return parts.join(' ');
}
