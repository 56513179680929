import { Component, ViewEncapsulation } from '@angular/core';
import { EntityError } from '@common/classes/entity-error';

export class EntityErrors {
    constructor(public errors: EntityError[]) {}
}

@Component({
    selector: 'app-entity-errors',
    templateUrl: './entity-errors.component.html',
    styleUrls: ['./entity-errors.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EntityErrorsComponent {
    errors: EntityError[];

    constructor(errors: EntityErrors) {
        this.errors = errors.errors;
    }

    trimmedName(name: string): string {
        if (!name) {
            return 'Field';
        }
        return name.replace(/([A-Z])/g, ' $1').trim();
    }
}
