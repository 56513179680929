import { Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { RememberStateService } from '@common/services/remember-state.service';
import { environment } from '@environments/environment.base';
import { PanelBarStateChangeEvent } from '@progress/kendo-angular-layout';

@Component({
    selector: 'app-filter',
    templateUrl: './app-filter.component.html',
    styleUrls: ['./app-filter.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppFilterComponent {
    @Input() expanded = true;
    @Input() hasMetaFilter = false; // TODO: Upgrade with dynamic filters passed as input (like in app-grid)
    @Output() clearFilter = new EventEmitter();
    @Output() search = new EventEmitter();

    @HostListener('document:keyup.enter', ['$event']) onEnterPress(/*event: KeyboardEvent*/) {
        this.search.emit();
    }

    private rememberFilterState = environment.settings.list.rememberFilterState;

    constructor(
        private rememberStateService: RememberStateService,
        private router: Router
    ) {}

    stateChange(event: PanelBarStateChangeEvent) {
        this.expanded = event.items.at(0).expanded;
        if (this.rememberFilterState) {
            this.rememberStateService.set<boolean>(`filterExpanded:${this.router.url}`, this.expanded);
        }
    }
}
