import { Component, Input } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { DateTimeInputConfig } from '../input.type';

/**
 * Datetime input component.
 * @param format - The format of the date.
 * @param minDate - The minimum date.
 * @param maxDate - The maximum date.
 * @param disabledDates - The disabled dates.
 */
@Component({
    selector: 'app-datetime-input',
    templateUrl: './datetime-input.component.html'
})
export class DatetimeInputComponent extends BaseInputComponent<Date> {
    @Input() format: DateTimeInputConfig['format'] = 'dd. MM. yyyy HH:mm';
    @Input() minDate: DateTimeInputConfig['minDate'];
    @Input() maxDate: DateTimeInputConfig['maxDate'];
    @Input() disabledDates: DateTimeInputConfig['disabledDates'];
}
