import { Component, Input } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { DateInputConfig } from '../input.type';

/**
 * Date input component.
 * @param format - The format of the date.
 * @param minDate - The minimum date.
 * @param maxDate - The maximum date.
 * @param disabledDates - The disabled dates.
 */
@Component({
    selector: 'app-date-input',
    templateUrl: './date-input.component.html'
})
export class DateInputComponent extends BaseInputComponent<Date> {
    @Input() format: DateInputConfig['format'] = 'dd. MM. yyyy';
    @Input() minDate: DateInputConfig['minDate'];
    @Input() maxDate: DateInputConfig['maxDate'];
    @Input() disabledDates: DateInputConfig['disabledDates'];
}
