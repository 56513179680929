<kendo-stepper
    [steps]="steps"
    [stepType]="stepType"
    [orientation]="orientation"
    [linear]="linear"
    [currentStep]="currentStep()"
    (currentStepChange)="onCurrentStepChange($event)">
    <ng-template kendoStepperLabelTemplate let-step>
        <span class="text-link hover:text-link-foreground">{{ step.label | translate }}</span>
    </ng-template>
</kendo-stepper>
