<app-input-label [label]="label" [labelPosition]="labelPosition" [for]="id" [isRequired]="isRequired">
    @if (isEditMode) {
        <kendo-daterange>
            <kendo-dateinput
                kendoDateRangeStartInput
                [ngClass]="generateInputStyle()"
                [disabled]="isDisabled"
                [isRequired]="isRequired"
                [format]="format"
                [(value)]="value().startDate"
                (valueChange)="onValueChange({ startDate: $event, endDate: value().endDate })" />
            <kendo-dateinput
                kendoDateRangeEndInput
                [ngClass]="generateInputStyle()"
                [isRequired]="isRequired"
                [disabled]="isDisabled"
                [format]="format"
                [(value)]="value().endDate"
                (valueChange)="onValueChange({ startDate: value().startDate, endDate: $event })" />
        </kendo-daterange>
        <app-input-error [error]="error()?.[0]" />
    } @else {
        <div class="pb-1 pt-1.5">{{ value().startDate | date: format }} - {{ value().endDate | date: format }}</div>
    }
</app-input-label>
