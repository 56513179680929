import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TimeService } from 'app/berth-plan/time.service';

@Component({
    selector: 'app-time-display',
    standalone: true,
    imports: [CommonModule, FormsModule],
    template: `
        <div class="group relative p-2 text-sm">
            <div class="z-10 hidden p-4 group-hover:block">
                <div class="mb-2">
                    <label class="mb-1 block" for="speed">Speed:</label>
                    <input
                        class="w-full rounded border px-2 py-1"
                        type="number"
                        id="speed"
                        min="0.1"
                        step="0.1"
                        [ngModel]="timeService.speed()"
                        (ngModelChange)="setSpeed($event)" />
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <button class="btn" (click)="resetTime()">Reset</button>
                    <button class="btn" (click)="timeService.incrementHours()">+1 Hour</button>
                    <button class="btn" (click)="timeService.decrementHours()">-1 Hour</button>
                    <button class="btn" (click)="timeService.incrementDays()">+1 Day</button>
                    <button class="btn" (click)="timeService.decrementDays()">-1 Day</button>
                </div>
            </div>
            <h2 class="text-lg font-semibold">{{ timeService.currentTime() | date: 'medium' }}</h2>
        </div>
    `,
    styles: [
        `
            .btn {
                @apply rounded bg-blue-500 px-2 py-1 text-white transition-colors hover:bg-blue-600;
            }
        `
    ]
})
export class TimeDisplayComponent {
    constructor(public timeService: TimeService) {}

    resetTime() {
        this.timeService.setCurrentTime(new Date(2024, 11, 15, 8, 0));
    }

    setSpeed(speed: number) {
        this.timeService.setSpeed(speed);
    }
}
