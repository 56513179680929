import { Component, Input, model } from '@angular/core';
import { InputConfig, InputType } from './input.type';

/**
 * General input component that handles all types of inputs.
 * @param value - The input value.
 * @param config - The input configuration.
 */
@Component({
    selector: 'app-input',
    templateUrl: './input.component.html'
})
export class InputComponent {
    value = model.required<any>();
    @Input() config: InputConfig;

    inputTypes = InputType;
}
