import { Component, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { FileInfo, SelectEvent } from '@progress/kendo-angular-upload';
import { BaseInputComponent } from '../base-input/base-input.component';
import { FileInputConfig } from '../input.type';

/**
 * File input component.
 * @param isMultiple - Whether multiple files can be selected.
 * @param showFileList - Whether the file list should be shown.
 * @param restrictions - The file restrictions.
 */
@Component({
    selector: 'app-file-input',
    templateUrl: './file-input.component.html'
})
export class FileInputComponent extends BaseInputComponent<{ content: string; name: string }[]> {
    @Input() isMultiple: FileInputConfig['isMultiple'];
    @Input() showFileList: FileInputConfig['showFileList'] = true;
    @Input() restrictions: FileInputConfig['restrictions'] = environment.settings.appControl.fileRestrictions;
    onFileSelected(event: SelectEvent) {
        event.files.forEach((file: FileInfo) => {
            this.loadFile(file, (content) => {
                this.value.update((arr) => {
                    if (!this.isMultiple) {
                        arr.splice(0, arr.length);
                    }
                    arr.push({ content, name: file.name });
                    return arr;
                });
            });
        });
    }

    private loadFile(file: FileInfo, onLoaded: (content: string) => void) {
        if (file.validationErrors?.length) {
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const str = event.target.result as string; // data:text/plain;base64,[CONTENT]
            onLoaded(str.substring(str.indexOf(',') + 1));
        };
        reader.readAsDataURL(file.rawFile);
    }
}
