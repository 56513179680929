import { CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, input, Input, OnInit, Output } from '@angular/core';
import interact from 'interactjs';
import { Bollard, Pier, Times, VesselPlan, VesselPlans } from '../neo-scheduler.interface';
import { flattenBollards } from '../utils';
import { VesselComponent } from './vessel/vessel.component';

@Component({
    selector: 'app-vessels',
    standalone: true,
    imports: [CommonModule, VesselComponent],
    templateUrl: './vessels.component.html',
    styleUrl: './vessels.component.scss'
})
export class VesselsComponent implements OnInit {
    @Input({ required: true }) times: Times = [];
    piers = input<Pier[]>([]);
    @Input() vessels: VesselPlans = [];
    @Output() vesselsChange = new EventEmitter<VesselPlans>();
    @Input({ required: true }) startDate!: Date;
    @Input({ required: true }) timeSlotMinutes!: number;
    @Input({ required: true }) rowScale!: number;
    @Input({ required: true }) timeSlotWidth!: number;
    @Input() snap = false;
    allBollards = computed<Bollard[]>(() => flattenBollards(this.piers()));

    ngOnInit() {
        this.initializeInteractJS();
    }

    onVesselChange(vessel: VesselPlan) {
        const index = this.vessels.findIndex((v) => v.vessel_announcement_id === vessel.vessel_announcement_id);
        if (index !== -1) {
            this.vessels[index] = vessel;
        }
        this.vesselsChange.emit(this.vessels);
    }

    private initializeInteractJS() {
        interact('.vessel')
            .resizable({
                edges: { right: true },
                listeners: {
                    move: (event) => this.resizeListener(event)
                }
            })
            .draggable({
                inertia: false,
                modifiers: [
                    interact.modifiers.restrictRect({
                        restriction: '.vessels-container',
                        endOnly: true
                    })
                ],
                autoScroll: true,
                listeners: {
                    move: this.dragMoveListener,
                    end: (event) => {
                        // TODO: snap to grid
                    }
                }
            });
    }

    private resizeListener(event: any) {
        const target = event.target;
        let newWidth = event.rect.width;

        // Snap to the nearest timeSlotWidth
        newWidth = Math.round(newWidth / this.timeSlotWidth) * this.timeSlotWidth;

        const x = parseFloat(target.getAttribute('data-x') || '0') + event.deltaRect.left;
        const y = parseFloat(target.getAttribute('data-y') || '0') + event.deltaRect.top;

        Object.assign(target.style, {
            width: `${newWidth}px`,
            transform: `translate(${x}px, ${y}px)`
        });

        target.setAttribute('data-x', x);
        target.setAttribute('data-y', y);

        this.updateOperationTime(target, newWidth);
        this.vesselsChange.emit(this.vessels);
    }

    private updateOperationTime(target: HTMLElement, newWidth: number) {
        const vessel = this.findVesselById(target);
        if (vessel) {
            const newDuration = (newWidth / this.timeSlotWidth) * this.timeSlotMinutes;
            vessel.operation_time = newDuration; // No need to round, as it's already snapped
            vessel.planned_undock_date = new Date(vessel.planned_dock_date.getTime() + newDuration * 60 * 1000);
            vessel.etd = new Date(vessel.eta.getTime() + newDuration * 60 * 1000); // TODO: check if this is working correctly
        }
    }

    private dragMoveListener = (event: any) => {
        const target = event.target;
        let x_window = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
        const y_window = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

        if (this.snap) {
            // currently implemented just for x axis and is working quite wonky.
            x_window = Math.floor((event.page.x - event.x0) / this.timeSlotWidth) * this.timeSlotWidth;
        }

        target.style.transform = `translate(${x_window}px, ${y_window}px)`;
        target.setAttribute('data-x', x_window);
        target.setAttribute('data-y', y_window);

        const container = document.querySelector('.vessels-container');
        if (!container) return;
        // calculations go wrong of window is scrolled
        const containerRect = container.getBoundingClientRect();
        const topLeftX = event.rect.left - containerRect.left;
        const topLeftY = event.rect.top - containerRect.top;
        // const bottomRightX = topLeftX + event.rect.width;
        const bottomRightY = topLeftY + event.rect.height;

        const vessel = this.findVesselById(target);
        if (vessel) {
            const startTimeId = Math.floor(topLeftX / this.timeSlotWidth);
            // const endTimeId = Math.ceil(bottomRightX / this.timeSlotWidth);
            const bollardStartId = this.findClosestBollard(topLeftY);
            const bollardEndId = this.findClosestBollard(bottomRightY);
            // console.log(bollardStartId, bollardEndId);
            // console.log(startTimeId, endTimeId);
            // console.log('topLeftX', topLeftX);
            // console.log('bottomRightX', bottomRightX);
            // console.log('topLeftY', topLeftY);
            // console.log('bottomRightY', bottomRightY);

            // Update vessel properties
            vessel.planned_dock_date = this.calculateDate(this.timeSlotMinutes, startTimeId);
            vessel.planned_undock_date = new Date(
                vessel.planned_dock_date.getTime() + vessel.operation_time * 60 * 1000
            );
            vessel.planned_bollard_start = bollardStartId;
            vessel.n_bollards = bollardEndId - bollardStartId + 1; // from bollard 0 to 1 it's 2 bollards

            // You can emit an event here if you want to notify the parent component of the changes
            this.vesselsChange.emit(this.vessels);
        }
    };

    private findClosestBollard(offset: number): number {
        let total = 0;
        let closest = 0;
        let minDifference = Number.POSITIVE_INFINITY;

        for (let i = 0; i < this.allBollards().length; i++) {
            const bollard = this.allBollards()[i];
            if (i > 0) {
                total += bollard.rowHeight!;
            }
            const difference = Math.abs(total - offset);
            if (difference < minDifference) {
                minDifference = difference;
                closest = i;
            }
        }

        return closest;
    }

    private calculateDate(timeSlotMinutes: number, timeId: number): Date {
        const totalMinutes = timeId * timeSlotMinutes;
        return new Date(this.startDate.getTime() + totalMinutes * 60 * 1000);
    }

    private findVesselById(target: HTMLElement): VesselPlans[number] | undefined {
        const vesselId = target.getAttribute('data-vessel-id');
        return this.vessels.find((v) => v.vessel_announcement_id === +vesselId);
    }
}
