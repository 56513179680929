import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import terminalColors from '@common/classes/terminal-colors';
import { PillDirective } from '@common/directives/pill.directive';
import { bootstrapLockFill, bootstrapUnlockFill } from '@ng-icons/bootstrap-icons';
import { NgIconsModule, provideIcons } from '@ng-icons/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { PopoverModule, TooltipModule } from '@progress/kendo-angular-tooltip';
import { VesselPlan } from 'app/neo-scheduler/neo-scheduler.interface';
import { dateDifferenceToDaysAndHoursAndMinutes } from 'app/neo-scheduler/utils';

@Component({
    selector: 'app-metadata',
    standalone: true,
    imports: [CommonModule, PillDirective, NgIconsModule, ButtonsModule, TooltipModule, PopoverModule],
    providers: [provideIcons({ bootstrapLockFill, bootstrapUnlockFill })],
    templateUrl: './metadata.component.html',
    styleUrl: './metadata.component.scss'
})
export class MetadataComponent {
    vessel = input<VesselPlan>();
    vesselChange = output<VesselPlan>();

    dateDiffString = dateDifferenceToDaysAndHoursAndMinutes;

    cargoToColor(cargo: string) {
        return terminalColors[cargo] || 'var(--line-color)';
    }

    onLockClick() {
        this.vessel().locked = !this.vessel().locked;
        this.vesselChange.emit(this.vessel());
    }
}
