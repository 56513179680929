import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import terminalColors from '@common/classes/terminal-colors';
import fontColorContrast from 'font-color-contrast';

@Directive({
    selector: '[pill]',
    standalone: true
})
export class PillDirective implements OnChanges {
    // Colors
    dark = '#222';
    light = '#fff';
    green = '#5DD879';
    darkgreen = '#2DBC4E';
    red = '#E56B77';
    darkred = '#D44B59';
    blue = '#59A9FF';
    orange = '#FD9A47';
    amber = '#FFC000';
    yellow = '#FFC107';
    grey = '#CED4DA';
    purple = '#BA55D3';
    black = '#000';

    // Formatting
    formatting = {
        maxHeight: '1.45em',
        display: 'block',
        lineHeight: '1.45em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 0.5em 0 0.5em',
        borderRadius: '4px',
        color: this.dark,
        fontWeight: 'bold',
        textAlign: 'center',
        textDecoration: 'none'
    };

    // Status, module & muted input
    @Input() pill: { status: string; module: string };

    constructor(private el: ElementRef) {
        // Apply formatting
        for (const prop in this.formatting) {
            if (Object.prototype.hasOwnProperty.call(this.el.nativeElement.style, prop)) {
                this.el.nativeElement.style[prop] = this.formatting[prop];
            }
        }
    }

    ngOnChanges() {
        const backgroundColor = this.pillColor(this.pill);
        this.el.nativeElement.style.backgroundColor = backgroundColor;
        this.el.nativeElement.style.color = fontColorContrast(backgroundColor);
    }

    private pillColor(input: any): string {
        const status = input.status?.toUpperCase();
        const module = input.module;

        if (module === 'VesselVisitStatus') {
            switch (status) {
                case 'P':
                    return this.blue;
                case 'AR':
                    return this.green;
                case 'N':
                    return this.purple;
            }
        }
        if (module === 'Terminal') {
            return terminalColors[status];
        }
        if (module === 'Priority') {
            const priority = parseInt(status, 10);
            if (priority >= 0 && priority <= 10) {
                return this.priorityColors[priority];
            }
        }
        return this.grey;
    }

    // Add new colors for priority gradient
    priorityColors = [
        this.grey, // 0
        '#FFE0B2', // 1
        '#FFCC80', // 2
        '#FFB74D', // 3
        '#FFA726', // 4
        '#FF9800', // 5
        '#FB8C00', // 6
        '#F57C00', // 7
        '#EF6C00', // 8
        '#E65100', // 9
        this.red // 10
    ];
}
