import { CommonModule, DatePipe, JsonPipe } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    Signal,
    ViewChild,
    computed,
    inject,
    input,
    signal
} from '@angular/core';
import { RememberStateService } from '@common/services/remember-state.service';
import { TimeService } from 'app/berth-plan/time.service';
import { Bollard, Port, Times, VesselPlans } from './neo-scheduler.interface';
import { flattenBollards, generateRowProperties, generateTimes, subtractFromArrayOfNumbers } from './utils';
import { VesselsComponent } from './vessels/vessels.component';

@Component({
    selector: 'app-neo-scheduler',
    standalone: true,
    imports: [DatePipe, JsonPipe, VesselsComponent, CommonModule],
    templateUrl: './neo-scheduler.component.html',
    styleUrl: './neo-scheduler.component.scss'
})
export class NeoSchedulerComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild('schedulerSidebar') schedulerSidebar!: ElementRef;
    @ViewChild('schedulerSidebarInner') schedulerSidebarInner!: ElementRef;
    @ViewChild('schedulerMain') schedulerMain!: ElementRef;
    @ViewChild('schedulerMainInner') schedulerMainInner!: ElementRef;
    @ViewChild('schedulerHeader') schedulerHeader!: ElementRef;
    @ViewChild('schedulerHeaderInner') schedulerHeaderInner!: ElementRef;

    private readonly ZOOM_STORAGE_KEY = 'neoSchedulerZoom';
    private readonly DEFAULT_TIME_SLOT_WIDTH_PX = 24;
    private readonly DEFAULT_ROW_SCALE = 2;

    @Input({ required: true }) port!: Port;
    @Input({ required: true }) vesselsPlan!: VesselPlans;
    @Output() vesselsPlanChange = new EventEmitter<VesselPlans>();
    planFrom = input.required<Date>();
    planTo = input.required<Date>();
    resolution = input<number>(30);
    @Input() rowScale = 2; // scale of pixels to meters
    @Input() timeSlotWidthPx = 24;
    @Input() minTimeSlotWidthPx = 12;
    @Input() maxTimeSlotWidthPx = 48;
    /**
     * Number of time slots to add before the first vessel's planned dock date
     */
    @Input() timeSlotsToAddBefore = 0;
    /**
     * Number of time slots to add after the last vessel's planned undock date. Default is 48.
     */
    @Input() timeSlotsToAddAfter = 48;
    /**
     * Minimum row scale to use when zooming in. Default is 1.
     */
    @Input() minRowScale = 1;
    /**
     * Maximum row scale to use when zooming out. Default is 4.
     */
    @Input() maxRowScale = 4;

    times: Signal<Times> = computed(() => generateTimes(this.actualFrom(), this.actualTo(), this.resolution()));
    portWithRowData: Port | null = null;
    allBollards: Bollard[] = [];
    vesselsPlan2!: VesselPlans;
    berthHeights: number[] = [];
    pierHeights: number[] = [];
    dateWidths: number[] = [];
    actualFrom = signal<Date | null>(null);
    actualTo = signal<Date | null>(null);
    currentTime = inject(TimeService).currentTime;
    ngZone = inject(NgZone);
    cdRef = inject(ChangeDetectorRef);
    rememberStateService = inject(RememberStateService);
    private resizeObserver: ResizeObserver | null = null;

    ngOnInit() {
        this.loadSavedZoomLevels();
        this.portWithRowData = generateRowProperties(this.port, this.rowScale);
        this.allBollards = flattenBollards(this.port.piers);
        this.berthHeights = this.calculateSidebarBerthHeights(0);
        this.pierHeights = this.calculateSidebarPierHeights(0);
        this.dateWidths = this.calculateHeaderDateWidths(0);
        this.calculateActualDateRange();
    }

    ngAfterViewInit() {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.syncScroll();
                this.setupResizeObserver();
                this.sizeMain();
                this.ngZone.run(() => {
                    this.cdRef.detectChanges();
                });
            });
        });
    }

    ngOnDestroy() {
        this.resizeObserver?.disconnect();
    }

    numberToTime(hour: number, minute: number) {
        // return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        return `${hour.toString().padStart(2, '0')}`;
    }

    currentTimeIndicatorPositionPx = computed(() => {
        const currentTime = this.currentTime();
        const actualFrom = this.actualFrom();
        const actualTo = this.actualTo();
        const mainInner = this.schedulerMainInner?.nativeElement as HTMLElement;

        if (!mainInner || !actualFrom || !actualTo || !currentTime) {
            return null;
        }

        const totalDuration = actualTo.getTime() - actualFrom.getTime();
        const currentPosition = currentTime.getTime() - actualFrom.getTime();
        const relativePosition = currentPosition / totalDuration;

        const scrollbarWidth = parseInt(
            getComputedStyle(document.documentElement).getPropertyValue('--scrollbar-width'),
            10
        );
        return Math.round(relativePosition * (mainInner.offsetWidth - scrollbarWidth));
    });

    /**
     * Calculate the actual date range to display based on the planned dates of the vessels
     * and the time slots to add before and after.
     */
    private calculateActualDateRange() {
        const planFrom = this.planFrom();
        const planTo = this.planTo();
        const lowestInputVesselFrom = this.vesselsPlan.reduce((acc, vessel) => {
            return acc < vessel.planned_dock_date ? acc : vessel.planned_dock_date;
        }, planFrom);
        const highestInputVesselTo = this.vesselsPlan.reduce((acc, vessel) => {
            return acc > vessel.planned_undock_date ? acc : vessel.planned_undock_date;
        }, planTo);
        const fromWithBuffer = new Date(
            lowestInputVesselFrom.getTime() - this.timeSlotsToAddBefore * this.resolution() * 60 * 1000
        );
        const toWithBuffer = new Date(
            highestInputVesselTo.getTime() + this.timeSlotsToAddAfter * this.resolution() * 60 * 1000
        );
        this.actualFrom.set(fromWithBuffer);
        this.actualTo.set(toWithBuffer);
    }

    private setupResizeObserver() {
        this.resizeObserver = new ResizeObserver(() => {
            this.ngZone.run(() => {
                this.sizeMain();
                this.cdRef.detectChanges();
            });
        });

        this.resizeObserver.observe(this.schedulerSidebarInner.nativeElement);
        this.resizeObserver.observe(this.schedulerHeaderInner.nativeElement);
    }

    sizeMain() {
        const sidebarInner = this.schedulerSidebarInner.nativeElement as HTMLElement;
        const mainInner = this.schedulerMainInner.nativeElement as HTMLElement;
        const headerInner = this.schedulerHeaderInner.nativeElement as HTMLElement;

        mainInner.style.height = `${sidebarInner.offsetHeight}px`;
        mainInner.style.width = `${headerInner.offsetWidth}px`;
    }

    updateVesselsPlan(updatedVesselsPlan: VesselPlans) {
        this.vesselsPlan = updatedVesselsPlan;
        this.vesselsPlanChange.emit(this.vesselsPlan);
    }

    private syncScroll() {
        const sidebar = this.schedulerSidebar.nativeElement;
        const main = this.schedulerMain.nativeElement;
        const header = this.schedulerHeader.nativeElement;

        main.addEventListener('scroll', () => {
            sidebar.scrollTop = main.scrollTop;
            header.scrollLeft = main.scrollLeft;
            this.berthHeights = this.calculateSidebarBerthHeights(main.scrollTop);
            this.pierHeights = this.calculateSidebarPierHeights(main.scrollTop);
            this.dateWidths = this.calculateHeaderDateWidths(main.scrollLeft);
            this.cdRef.detectChanges();
        });
    }

    /**
     * Calculate the heights of the berths in the sidebar
     * @param scrollTop - The current scroll position of the sidebar
     * @returns An array of heights for each berth
     */
    private calculateSidebarBerthHeights(scrollTop: number): number[] {
        const fullBerthHeights = this.portWithRowData?.piers.flatMap((pier) => {
            return pier.berths.map((berth) => {
                return berth.bollards.reduce((acc, bollard) => {
                    return acc + bollard.rowHeight;
                }, 0);
            });
        });
        const remainingBerthHeights = subtractFromArrayOfNumbers(fullBerthHeights, scrollTop);
        return remainingBerthHeights;
    }

    /**
     * Calculate the heights of the piers in the sidebar
     * @param scrollTop - The current scroll position of the sidebar
     * @returns An array of heights for each pier
     */
    private calculateSidebarPierHeights(scrollTop: number): number[] {
        const fullPierHeights = this.portWithRowData?.piers.map((pier) => {
            return pier.berths.reduce((accBerth, berth) => {
                return (
                    accBerth +
                    berth.bollards.reduce((accBollard, bollard) => {
                        return accBollard + bollard.rowHeight;
                    }, 0)
                );
            }, 0);
        });
        const remainingPierHeights = subtractFromArrayOfNumbers(fullPierHeights, scrollTop);
        return remainingPierHeights;
    }

    /**
     * Calculate the widths of the dates in the header
     * @param scrollLeft - The current scroll position of the header
     * @returns An array of widths for each date
     */
    private calculateHeaderDateWidths(scrollLeft: number): number[] {
        const fullDateWidths = this.times().map((day) => {
            return day.hours.length * this.timeSlotWidthPx;
        });
        const remainingDateWidths = subtractFromArrayOfNumbers(fullDateWidths, scrollLeft);
        return remainingDateWidths;
    }

    calculateBerthIndex(pierIndex: number, berthIndex: number): number {
        let index = 0;
        for (let i = 0; i < pierIndex; i++) {
            index += this.portWithRowData?.piers[i].berths.length || 0;
        }
        return index + berthIndex;
    }

    onHeaderWheel(event: WheelEvent) {
        event.preventDefault();
        this.handleZoom(event);
    }

    private handleZoom(event: WheelEvent) {
        const zoomFactor = event.deltaY > 0 ? 0.9 : 1.1;
        const newWidth = Math.min(
            Math.max(this.timeSlotWidthPx * zoomFactor, this.minTimeSlotWidthPx),
            this.maxTimeSlotWidthPx
        );

        if (newWidth !== this.timeSlotWidthPx) {
            this.timeSlotWidthPx = newWidth;
            this.saveZoomLevels();
            this.updateZoom();
        }
    }

    onSidebarWheel(event: WheelEvent) {
        event.preventDefault();
        this.handleSidebarZoom(event);
    }

    private handleSidebarZoom(event: WheelEvent) {
        const zoomFactor = event.deltaY > 0 ? 0.9 : 1.1;
        const newScale = Math.min(Math.max(this.rowScale * zoomFactor, this.minRowScale), this.maxRowScale);

        if (newScale !== this.rowScale) {
            this.rowScale = newScale;
            this.saveZoomLevels();
            this.updateZoom();
        }
    }

    private loadSavedZoomLevels() {
        const savedZoom = this.rememberStateService.get<{ timeSlotWidthPx: number; rowScale: number }>(
            this.ZOOM_STORAGE_KEY
        );
        if (savedZoom) {
            this.timeSlotWidthPx = savedZoom.timeSlotWidthPx;
            this.rowScale = savedZoom.rowScale;
        }
    }

    private saveZoomLevels() {
        this.rememberStateService.set(this.ZOOM_STORAGE_KEY, {
            timeSlotWidthPx: this.timeSlotWidthPx,
            rowScale: this.rowScale
        });
    }

    resetZoom() {
        this.timeSlotWidthPx = this.DEFAULT_TIME_SLOT_WIDTH_PX;
        this.rowScale = this.DEFAULT_ROW_SCALE;
        this.saveZoomLevels();
        this.updateZoom();
    }

    private updateZoom() {
        this.portWithRowData = generateRowProperties(this.port, this.rowScale);
        this.berthHeights = this.calculateSidebarBerthHeights(this.schedulerMain.nativeElement.scrollTop);
        this.pierHeights = this.calculateSidebarPierHeights(this.schedulerMain.nativeElement.scrollTop);
        this.dateWidths = this.calculateHeaderDateWidths(this.schedulerHeader.nativeElement.scrollLeft);
        this.sizeMain();
        this.cdRef.detectChanges();
    }
}
