import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-check-icon',
    template: `
        <ng-icon *ngIf="check" class="text-success" name="faSolidCircleCheck" />
        <ng-icon *ngIf="!check && !hideFalse" class="text-danger" name="faSolidCircleXmark" />
    `
})
export class CheckIconComponent {
    @Input() check: boolean;
    @Input() hideFalse: boolean = false;
}
