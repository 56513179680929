import { Component, Input, model, Signal, viewChild } from '@angular/core';
import { StepperComponent as KendoStepper, StepperOrientation, StepType } from '@progress/kendo-angular-layout';

export type Step = {
    label: string;
    text?: string;
    icon?: string;
    iconClass?: string;
    disabled?: boolean;
    optional?: boolean;
};

/**
 * Stepper component for navigating through a sequence of steps. Based on the Kendo UI Stepper component.
 * @param steps - Array of steps to be displayed in the stepper.
 * @param validateStep - Function to validate the step. Returns true if the step is valid.
 * @param orientation - Orientation of the stepper. Can be 'horizontal' or 'vertical'.
 * @param stepType - Type of the step. Can be 'indicator', 'label' or 'full'.
 * @param linear - If true, the user can only navigate to the next step if the current step is valid.
 *
 * Look at the [Kendo UI Stepper component](https://www.telerik.com/kendo-angular-ui/components/layout/stepper/) for more details.
 */

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html'
})
export class StepperComponent {
    @Input() steps: Step[] = [];
    @Input() validateStep: (stepValue?: number) => boolean = () => true;
    @Input() orientation: StepperOrientation = 'horizontal';
    @Input() stepType: StepType = 'full';
    @Input() linear = true;
    currentStep = model(0);

    stepper: Signal<KendoStepper> = viewChild.required(KendoStepper);

    // method for navigating through the stepper
    onCurrentStepChange(index: number) {
        if (!this.validateStep(index)) {
            this.stepper().currentStep = this.currentStep();
            return;
        }
        this.currentStep.set(index);
    }

    // method for navigating through next/previous buttons
    onStepChange(value: number) {
        if (!this.validateStep(value)) {
            this.stepper().currentStep = this.currentStep();
            return;
        }
        this.currentStep.update((prev) => prev + value);
    }
}
