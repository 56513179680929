import { Injectable, effect, signal } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TimeService {
    private currentTimeSignal = signal<Date>(new Date(2024, 11, 15, 4, 0));
    private speedSignal = signal<number>(1);

    public currentTime = this.currentTimeSignal.asReadonly();
    public speed = this.speedSignal.asReadonly();

    constructor() {
        effect(() => {
            const interval = setInterval(() => {
                const newTime = new Date(this.currentTimeSignal());
                newTime.setSeconds(newTime.getSeconds() + this.speedSignal());
                this.currentTimeSignal.set(newTime);
            }, 1000);

            return () => clearInterval(interval);
        });
    }

    setCurrentTime(date: Date): void {
        this.currentTimeSignal.set(date);
    }

    setSpeed(speed: number): void {
        if (speed > 0) {
            this.speedSignal.set(speed);
        }
    }

    incrementHours(hours: number = 1): void {
        this.currentTimeSignal.update((time) => {
            const newTime = new Date(time);
            newTime.setHours(newTime.getHours() + hours);
            return newTime;
        });
    }

    decrementHours(hours: number = 1): void {
        this.incrementHours(-hours);
    }

    incrementDays(days: number = 1): void {
        this.currentTimeSignal.update((time) => {
            const newTime = new Date(time);
            newTime.setDate(newTime.getDate() + days);
            return newTime;
        });
    }

    decrementDays(days: number = 1): void {
        this.incrementDays(-days);
    }
}
