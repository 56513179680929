import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { IconKey } from '@common/classes/icons';
import { PermissionType } from '@common/models/user';
import { PropertyFunction } from '@common/models/util.interface';
import { SVGIcon } from '@progress/kendo-svg-icons';

export interface NavItem extends PanelBarItemModel {
    readonly permissions?: PermissionType | PermissionType[];
}

export const NAVIGATION: NavItem[] = [
    {
        title: _('Dashboard'),
        icon: 'faSolidInbox',
        path: '/dashboard',
        id: 'dashboard',
        selected: true
    },
    // {
    //     title: _('Vessels'),
    //     icon: 'faSolidShip',
    //     path: '/vessel',
    //     id: 'vessels'
    // },
    {
        title: _('Announcements'),
        icon: 'faSolidBullhorn',
        path: '/vessel-announcement/list',
        id: 'vessel-announcement'
    },
    {
        title: _('Berth Planning With AI'),
        icon: 'faSolidWandMagicSparkles',
        path: '/berth-plan/list',
        id: 'berth-plan'
    },
    {
        title: _('Vessel Visits'),
        icon: 'faSolidLocationDot',
        path: '/vessel-visit/list',
        id: 'vessel-visit'
    },
    {
        title: _('Codelists'),
        icon: 'faSolidList',
        path: '/administration/codelists',
        id: 'codelists'
    }
];

const flatten = (members: NavItem[]): NavItem[] => {
    let children = [];

    return members
        .map((m) => {
            if (m.children && m.children.length) {
                children = [...children, ...m.children];
            }
            return m;
        })
        .concat(children.length ? flatten(children) : children);
};

export const NAVIGATION_FLAT = flatten(NAVIGATION);

export interface PanelBarItemModel {
    // Sets the title of the PanelBar item.
    title: string;

    // Allows the component to set the `"id"` property of each item.
    id: string;

    //Defines the icon that will be rendered next to the title.
    icon: IconKey;

    // Defines the icon that will be rendered next to the title by using a custom CSS class.
    iconClass?: string;

    // Defines an SVGIcon to be rendered.
    // The input can take either an [existing Kendo SVG icon](slug:svgicon_list) or a custom one.
    svgIcon?: SVGIcon;

    // Defines the location of the image that will be displayed next to the title.
    imageUrl?: string;

    // When set to `true`, disables a PanelBar item.
    disabled?: boolean;

    // When set to `true`, expands the PanelBar item.
    expanded?: boolean;

    // Sets the focused state of a PanelBar item.
    focused?: boolean;

    // Sets the selected state of a PanelBar item.
    selected?: boolean;

    // Specifies if the item will be hidden.
    hidden?: boolean | PropertyFunction<boolean>;

    // Defines the child items as an array.
    children?: PanelBarItemModel[];

    // Sets the content of the PanelBar item.
    content?: any;

    // Sets permissions for the PanelBar item.
    readonly permissions?: PermissionType | PermissionType[];

    // Sets the path of the PanelBar item.
    path?: string;
}
