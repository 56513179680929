<div class="flex h-full">
    <div class="flex flex-col">
        <div class="shrink-0 basis-[var(--top-row-height)]">
            <!-- top left corner of the view, between the sidebar and header -->
        </div>
        <div
            class="grow overflow-hidden pt-[var(--grid-padding-top)]"
            #schedulerSidebar
            (wheel)="onSidebarWheel($event)">
            <div class="relative flex flex-col" #schedulerSidebarInner>
                <!-- Sidebar for piers -->
                <div class="absolute bottom-0 w-full">
                    @for (pier of portWithRowData?.piers; track $index) {
                        <div
                            class="relative z-10 w-full overflow-hidden"
                            [ngStyle]="{ height: pierHeights[$index] + 'px' }">
                            <div class="pier-divider"></div>
                            <div class="pier-name">{{ pier.name }}</div>
                        </div>
                    }
                </div>
                <!-- Sidebar for berths -->
                <div class="absolute bottom-0 w-full">
                    @for (pier of portWithRowData?.piers; track pier.name; let i = $index) {
                        @for (berth of pier.berths; track berth.name; let j = $index) {
                            <div
                                class="relative w-full overflow-hidden"
                                [ngStyle]="{ height: berthHeights[calculateBerthIndex(i, j)] + 'px' }">
                                <div class="berth-divider"></div>
                                <div class="berth-name absolute bottom-0 ml-8 h-full min-h-5">{{ berth.name }}</div>
                            </div>
                        }
                    }
                </div>
                @for (pier of portWithRowData?.piers; track $index) {
                    @for (berth of pier.berths; track $index) {
                        @for (bollard of berth.bollards; track $index) {
                            <div
                                class="scheduler-row"
                                [ngStyle]="{ height: bollard.rowHeight + 'px' }"
                                [ngClass]="{ divider: bollard.final }">
                                <span>{{ bollard.id_within_pier }}</span>
                                <div class="bollard-col-divider"></div>
                            </div>
                        }
                    }
                }
            </div>
            <div class="h-[var(--scrollbar-width)] shrink-0">
                <!-- Here to account for the scrollbar height -->
            </div>
        </div>
    </div>
    <div class="flex flex-col overflow-hidden">
        <div class="flex shrink-0 basis-[var(--top-row-height)] overflow-hidden" #schedulerHeader>
            <div class="flex w-max flex-col" (wheel)="onHeaderWheel($event)">
                <div class="relative w-full basis-5">
                    <div class="absolute right-0 flex">
                        @for (day of times(); track $index) {
                            <div class="date-header relative" [ngStyle]="{ width: dateWidths[$index] + 'px' }">
                                <div class="absolute right-0 w-full min-w-fit pr-4">
                                    {{ day.date | date: 'shortDate' }}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div class="flex w-max grow" #schedulerHeaderInner>
                    @for (day of times(); track $index) {
                        @for (time of day.hours; track $index) {
                            <div class="time-header flex justify-center" [ngStyle]="{ width: timeSlotWidthPx + 'px' }">
                                <div class="time-header-divider"></div>
                                @if (time.minute === 0) {
                                    <span>
                                        {{ numberToTime(time.hour, time.minute) }}
                                    </span>
                                }
                            </div>
                        }
                    }
                </div>
            </div>
            <div class="w-[var(--scrollbar-width)] shrink-0">
                <!-- Here to account for the scrollbar width -->
            </div>
        </div>
        <div class="grow overflow-y-auto pt-[var(--grid-padding-top)]" #schedulerMain>
            <div class="relative" #schedulerMainInner>
                @if (portWithRowData && portWithRowData.piers) {
                    <app-vessels
                        class="absolute inset-0 z-10"
                        [times]="times()"
                        [startDate]="actualFrom()"
                        [piers]="portWithRowData.piers"
                        [vessels]="vesselsPlan"
                        [timeSlotMinutes]="resolution()"
                        [rowScale]="rowScale"
                        [timeSlotWidth]="timeSlotWidthPx"
                        (vesselsChange)="updateVesselsPlan($event)"></app-vessels>
                }
                <!-- Render visual grid -->
                <div class="scheduler-visual-grid">
                    <!-- Vertical lines -->
                    <div class="scheduler-vertical-lines">
                        <div class="grid-line vertical"></div>
                        @for (day of times(); track $index) {
                            @for (time of day.hours; track $index) {
                                <div class="grid-line vertical"></div>
                            }
                        }
                    </div>
                    <!-- Horizontal lines -->
                    <div class="scheduler-horizontal-lines">
                        <div class="grid-line horizontal"></div>
                        @for (pier of portWithRowData?.piers; track $index) {
                            @for (berth of pier.berths; track $index) {
                                @for (bollard of berth.bollards; track $index) {
                                    <div
                                        class="grid-line horizontal"
                                        [ngStyle]="{ height: bollard.rowHeight + 'px' }"
                                        [ngClass]="{ divider: bollard.final }"></div>
                                }
                            }
                        }
                    </div>
                </div>
                <!-- Current time indicator -->
                <div
                    class="absolute left-0 top-0 h-full w-0.5 -translate-x-1/2 bg-red-500"
                    [ngStyle]="{ left: currentTimeIndicatorPositionPx() + 'px' }"></div>
            </div>
        </div>
    </div>
</div>
