import { Component, Input } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { TextareaInputConfig } from '../input.type';

/**
 * Textarea input component.
 * @param maxLength - The maximum length of the text.
 * @param rows - The number of rows.
 */
@Component({
    selector: 'app-textarea-input',
    templateUrl: './textarea-input.component.html'
})
export class TextareaInputComponent extends BaseInputComponent<string> {
    @Input() maxLength: TextareaInputConfig['maxLength'];
    @Input() rows: TextareaInputConfig['rows'];
}
