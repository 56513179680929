<div
    class="metadata-container flex h-full items-center overflow-hidden rounded border border-[var(--vessel-bg-color)] bg-[var(--vessel-bg-color)]"
    [ngStyle]="{ 'background-color': vessel().bg_color }">
    <div
        class="flex h-full flex-col justify-between p-2"
        [ngStyle]="{ 'background-color': cargoToColor(vessel().cargo_type) }">
        <div class="text-center">{{ vessel().planned_bollard_start }}</div>
        <div class="text-center">{{ vessel().planned_bollard_start + vessel().n_bollards }}</div>
    </div>
    <div class="flex h-full grow flex-col justify-between p-2 text-white">
        <div class="flex justify-between">
            <div class="font-bold text-gray-200" kendoPopoverAnchor [popover]="popoverContent">{{ vessel().name }}</div>
            <div class="flex items-center gap-2">
                @if (vessel().priority) {
                    <div
                        class="rounded p-1 text-center"
                        [pill]="{ status: vessel().priority.toString(), module: 'Priority' }">
                        {{ vessel().priority }}
                    </div>
                }
                <div class="rounded p-1 text-center" [pill]="{ status: vessel().cargo_type, module: 'Terminal' }">
                    {{ vessel().cargo_type }}
                </div>
            </div>
        </div>
        <div class="hide-if-short flex items-center justify-between">
            <div class="flex flex-col">
                <div class="text-lg font-bold">{{ vessel().planned_dock_date | date: 'HH:mm' }}</div>
                <div class="text-gray-200">{{ vessel().planned_dock_date | date: 'dd MMM' }}</div>
            </div>
            <div class="hide-if-narrow grow flex-col items-center">
                <div class="flex items-center text-[var(--line-color)]">
                    <div class="mx-1 h-2 w-2 rounded-full border-2 border-[var(--line-color)]"></div>
                    <div class="dashed-line grow"></div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
                        <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M2 20a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2-1a2.4 2.4 0 0 1 2-1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2-1a2.4 2.4 0 0 1 2-1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2-1M4 18l-1-5h18l-2 4M5 13V7h8l4 6M7 7V3H6" />
                    </svg>
                    <div class="dashed-line grow"></div>
                    <div class="mx-1 h-2 w-2 rounded-full border-2 border-[var(--line-color)]"></div>
                </div>
                <div class="flex justify-center">
                    <div class="text-[var(--line-color)]">
                        {{ dateDiffString(vessel().planned_dock_date, vessel().planned_undock_date) }}
                    </div>
                </div>
            </div>
            <div class="flex flex-col">
                <div class="text-lg font-bold">{{ vessel().planned_undock_date | date: 'HH:mm' }}</div>
                <div class="text-gray-200">{{ vessel().planned_undock_date | date: 'dd MMM' }}</div>
            </div>
        </div>
        <div>
            <div class="flex justify-between">
                @if (vessel().locked) {
                    <button class="text-white" kendoButton fillMode="clear" (click)="onLockClick()">
                        <ng-icon name="bootstrapLockFill"></ng-icon>
                    </button>
                } @else {
                    <button class="text-white" kendoButton fillMode="clear" (click)="onLockClick()">
                        <ng-icon name="bootstrapUnlockFill"></ng-icon>
                    </button>
                }
                <div class="flex items-center px-2">
                    @if (vessel().hazmat) {
                        <svg
                            kendoTooltip
                            title="Hazmat"
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.5em"
                            height="1.5em"
                            viewBox="0 0 36 36">
                            <path
                                fill="#ffcc4d"
                                d="M2.653 35C.811 35-.001 33.662.847 32.027L16.456 1.972c.849-1.635 2.238-1.635 3.087 0l15.609 30.056c.85 1.634.037 2.972-1.805 2.972z" />
                            <path
                                fill="#231f20"
                                d="M15.583 28.953a2.42 2.42 0 0 1 2.419-2.418a2.42 2.42 0 0 1 2.418 2.418a2.42 2.42 0 0 1-2.418 2.419a2.42 2.42 0 0 1-2.419-2.419m.186-18.293c0-1.302.961-2.108 2.232-2.108c1.241 0 2.233.837 2.233 2.108v11.938c0 1.271-.992 2.108-2.233 2.108c-1.271 0-2.232-.807-2.232-2.108z" />
                        </svg>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Popover content -->
<kendo-popover #popoverContent width="350px">
    <ng-template kendoPopoverBodyTemplate>
        <div
            class="flex h-full items-center overflow-hidden rounded border border-[var(--vessel-bg-color)] bg-[var(--vessel-bg-color)]"
            [ngStyle]="{ 'background-color': vessel().bg_color }">
            <!-- <div
                class="flex h-full flex-col justify-between p-2"
                [ngStyle]="{ 'background-color': cargoToColor(vessel().cargo_type) }">
                <div class="text-center">{{ vessel().planned_bollard_start }}</div>
                <div class="text-center">{{ vessel().planned_bollard_start + vessel().n_bollards }}</div>
            </div> -->
            <div class="flex h-full grow flex-col justify-between p-2 text-white">
                <div class="flex justify-between gap-2">
                    <div class="font-bold text-gray-200">{{ vessel().name }}</div>
                    <div class="flex items-center gap-2">
                        @if (vessel().priority) {
                            <div
                                class="rounded p-1 text-center"
                                [pill]="{ status: vessel().priority.toString(), module: 'Priority' }">
                                {{ vessel().priority }}
                            </div>
                        }
                        <div
                            class="rounded p-1 text-center"
                            [pill]="{ status: vessel().cargo_type, module: 'Terminal' }">
                            {{ vessel().cargo_type }}
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-between gap-2">
                    <div class="flex flex-col">
                        <div class="text-lg font-bold">{{ vessel().planned_dock_date | date: 'HH:mm' }}</div>
                        <div class="text-gray-200">{{ vessel().planned_dock_date | date: 'dd MMM' }}</div>
                    </div>
                    <div class="grow flex-col items-center">
                        <div class="flex items-center text-[var(--line-color)]">
                            <div class="mx-1 h-2 w-2 rounded-full border-2 border-[var(--line-color)]"></div>
                            <div class="dashed-line grow"></div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M2 20a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2-1a2.4 2.4 0 0 1 2-1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2-1a2.4 2.4 0 0 1 2-1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2-1M4 18l-1-5h18l-2 4M5 13V7h8l4 6M7 7V3H6" />
                            </svg>
                            <div class="dashed-line grow"></div>
                            <div class="mx-1 h-2 w-2 rounded-full border-2 border-[var(--line-color)]"></div>
                        </div>
                        <div class="flex justify-center">
                            <div class="text-[var(--line-color)]">
                                {{ dateDiffString(vessel().planned_dock_date, vessel().planned_undock_date) }}
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="text-lg font-bold">{{ vessel().planned_undock_date | date: 'HH:mm' }}</div>
                        <div class="text-gray-200">{{ vessel().planned_undock_date | date: 'dd MMM' }}</div>
                    </div>
                </div>
                <div>
                    <div class="flex justify-between">
                        @if (vessel().locked) {
                            <button class="text-white" kendoButton fillMode="clear" (click)="onLockClick()">
                                <ng-icon name="bootstrapLockFill"></ng-icon>
                            </button>
                        } @else {
                            <button class="text-white" kendoButton fillMode="clear" (click)="onLockClick()">
                                <ng-icon name="bootstrapUnlockFill"></ng-icon>
                            </button>
                        }
                        <div class="flex items-center px-2">
                            @if (vessel().hazmat) {
                                <svg
                                    kendoTooltip
                                    title="Hazmat"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1.5em"
                                    height="1.5em"
                                    viewBox="0 0 36 36">
                                    <path
                                        fill="#ffcc4d"
                                        d="M2.653 35C.811 35-.001 33.662.847 32.027L16.456 1.972c.849-1.635 2.238-1.635 3.087 0l15.609 30.056c.85 1.634.037 2.972-1.805 2.972z" />
                                    <path
                                        fill="#231f20"
                                        d="M15.583 28.953a2.42 2.42 0 0 1 2.419-2.418a2.42 2.42 0 0 1 2.418 2.418a2.42 2.42 0 0 1-2.418 2.419a2.42 2.42 0 0 1-2.419-2.419m.186-18.293c0-1.302.961-2.108 2.232-2.108c1.241 0 2.233.837 2.233 2.108v11.938c0 1.271-.992 2.108-2.233 2.108c-1.271 0-2.232-.807-2.232-2.108z" />
                                </svg>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</kendo-popover>
