import { AfterContentChecked, Component, EventEmitter, Input, Output } from '@angular/core';
import { fade } from '@common/animations/fade';
import { Setting } from '@common/interfaces/setting.interface';
import { SettingKeyCodes } from '@common/known-types/app-setting-key.codes';
import { User } from '@common/models/user';
import { CommonService } from '@common/services/common.service';
import { environment } from '@environments/environment.base';

@Component({
    selector: 'app-sidebar-profile',
    templateUrl: './sidebar-profile.component.html',
    animations: [fade]
})
export class SidebarProfileComponent implements AfterContentChecked {
    @Output() togglePinSidebar = new EventEmitter();
    @Output() toggleTheme = new EventEmitter();
    @Output() uploadImage = new EventEmitter();
    @Input() isSidebarExpanded: boolean;
    @Input() isSidebarPinned: boolean;
    @Input() currentUser: User;
    @Input() darkTheme: boolean;
    languages = this.commonService.i18nService.languages;
    currentLanguage = this.commonService.i18nService.currentLanguage;
    languageFlag = environment.settings.login.languageFlag;

    constructor(private commonService: CommonService) {}

    ngAfterContentChecked(): void {
        this.getLanguageFlagValue();
    }

    logout() {
        this.commonService.dialogService
            .confirm({
                options: {
                    title: this.commonService.translateService.instant('Sign Out') as string,
                    message: this.commonService.translateService.instant('Are you sure you want to sign out?') as string
                }
            })
            .then((result) => {
                if (result === true) {
                    this.commonService.router.navigate(['/logout']);
                }
            });
    }

    factoryReset() {
        this.commonService.dialogService
            .confirm({
                options: {
                    title: this.commonService.translateService.instant('Reset all the data') as string,
                    message: this.commonService.translateService.instant(
                        'Are you sure you want to reset data to factory defaults?'
                    ) as string
                }
            })
            .then((result) => {
                if (result === true) {
                    this.commonService.router.navigate(['/reset']);
                }
            });
    }

    navigateToUserSettings = () => this.commonService.router.navigate(['/user-settings']);

    private getLanguageFlagValue() {
        const settings = this.commonService.queryService.queryClient.getQueryData<Setting[]>(['settings']);
        if (settings) {
            const languageFlagSetting = settings?.find((s: Setting) => s.key === SettingKeyCodes.LanguageFlag);
            if (languageFlagSetting) this.languageFlag = languageFlagSetting.value === 'true';
        }
    }
}
