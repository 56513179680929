import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { PropertyFunction } from '@common/models/util.interface';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {
    @Input() variant:
        | 'primary'
        | 'secondary'
        | 'danger'
        | 'warning'
        | 'info'
        | 'success'
        | 'link'
        | 'light'
        | 'excel'
        | 'pdf'
        | 'csv';
    @Input() class: string;
    @Input() icon: IconKey | PropertyFunction<IconKey>;
    @Input() iconPosition: 'start' | 'end';
    @Input() iconClass: string | string[] | Set<string> | { [klass: string]: any };
    @Input() iconOnly?: boolean | PropertyFunction<boolean>;
    @Input() disabled: boolean;
    @Input() loading: boolean;
    @Input() type: 'button' | 'submit' = 'button';
    @Input() size: 'sm' | 'md' | 'lg' = 'md';
    @Input() tooltip: string | undefined;
    @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';
    @Output() clicked: EventEmitter<void> = new EventEmitter();

    @HostBinding('attr.class')
    get btnClass() {
        return '';
    }
}
