<app-input-label [label]="label" [labelPosition]="labelPosition" [for]="id" [isRequired]="isRequired">
    @if (isEditMode) {
        <kendo-textbox
            [type]="password ? 'password' : 'text'"
            [title]="title | translate"
            [placeholder]="placeholder | translate"
            [maxlength]="maxLength"
            [minlength]="minLength"
            [pattern]="pattern"
            [disabled]="isDisabled"
            [ngClass]="generateInputStyle()"
            [(ngModel)]="value"
            (valueChange)="onValueChange($event)">
            <ng-template kendoTextBoxSuffixTemplate>
                <ng-content select=".textbox-suffix"></ng-content>
            </ng-template>
        </kendo-textbox>
        <app-input-error [error]="error()?.[0]" />
    } @else {
        <div class="w-full rounded-sm border px-2 py-1">{{ value() }}</div>
    }
</app-input-label>
