@switch (config.type) {
    @case (inputTypes.TEXT) {
        <app-text-input
            [id]="config.id"
            [title]="config.title"
            [label]="config.label"
            [labelPosition]="config.labelPosition"
            [placeholder]="config.placeholder"
            [maxLength]="config.maxLength"
            [isDisabled]="config.isDisabled"
            [isRequired]="config.isRequired"
            [isEditMode]="config.isEditMode"
            [(value)]="value" />
    }
    @case (inputTypes.NUMBER) {
        <app-number-input
            [id]="config.id"
            [title]="config.title"
            [label]="config.label"
            [labelPosition]="config.labelPosition"
            [placeholder]="config.placeholder"
            [format]="config.format"
            [min]="config.min"
            [max]="config.max"
            [decimals]="config.decimals"
            [isDisabled]="config.isDisabled"
            [isRequired]="config.isRequired"
            [isEditMode]="config.isEditMode"
            [(value)]="value" />
    }
    @case (inputTypes.TEXTAREA) {
        <app-textarea-input
            [id]="config.id"
            [title]="config.title"
            [label]="config.label"
            [labelPosition]="config.labelPosition"
            [placeholder]="config.placeholder"
            [maxLength]="config.maxLength"
            [isDisabled]="config.isDisabled"
            [isRequired]="config.isRequired"
            [isEditMode]="config.isEditMode"
            [(value)]="value" />
    }
    @case (inputTypes.BOOLEAN) {
        <app-boolean-input
            [id]="config.id"
            [title]="config.title"
            [label]="config.label"
            [labelPosition]="config.labelPosition"
            [isEditMode]="config.isEditMode"
            [isDisabled]="config.isDisabled"
            [isRequired]="config.isRequired"
            [nullable]="config.nullable"
            [(value)]="value" />
    }
    @case (inputTypes.DATE) {
        <app-date-input
            [id]="config.id"
            [title]="config.title"
            [label]="config.label"
            [labelPosition]="config.labelPosition"
            [placeholder]="config.placeholder"
            [format]="config.format"
            [disabledDates]="config.disabledDates"
            [minDate]="config.minDate"
            [maxDate]="config.maxDate"
            [isDisabled]="config.isDisabled"
            [isRequired]="config.isRequired"
            [isEditMode]="config.isEditMode"
            [(value)]="value" />
    }
    @case (inputTypes.DATETIME) {
        <app-datetime-input
            [id]="config.id"
            [title]="config.title"
            [label]="config.label"
            [labelPosition]="config.labelPosition"
            [placeholder]="config.placeholder"
            [format]="config.format"
            [disabledDates]="config.disabledDates"
            [minDate]="config.minDate"
            [maxDate]="config.maxDate"
            [isDisabled]="config.isDisabled"
            [isRequired]="config.isRequired"
            [isEditMode]="config.isEditMode"
            [(value)]="value" />
    }
    @case (inputTypes.DATERANGE) {
        <app-daterange-input
            [id]="config.id"
            [title]="config.title"
            [label]="config.label"
            [labelPosition]="config.labelPosition"
            [placeholder]="config.placeholder"
            [format]="config.format"
            [minDate]="config.minDate"
            [maxDate]="config.maxDate"
            [isDisabled]="config.isDisabled"
            [isRequired]="config.isRequired"
            [isEditMode]="config.isEditMode"
            [(value)]="value" />
    }
    @case (inputTypes.FILE) {
        <app-file-input
            [id]="config.id"
            [title]="config.title"
            [label]="config.label"
            [labelPosition]="config.labelPosition"
            [showFileList]="config.showFileList"
            [restrictions]="config.restrictions"
            [isEditMode]="config.isEditMode"
            [isDisabled]="config.isDisabled"
            [isMultiple]="config.isMultiple"
            [isRequired]="config.isRequired"
            [(value)]="value" />
    }
    @case (inputTypes.SELECT) {
        <app-select-input
            [id]="config.id"
            [title]="config.title"
            [label]="config.label"
            [labelPosition]="config.labelPosition"
            [options]="config.options"
            [textField]="config.textField"
            [valueField]="config.valueField"
            [popupSettings]="config.popupSettings"
            [defaultItem]="config.defaultItem"
            [customFilter]="config.customFilter"
            [isFilterable]="config.isFilterable"
            [isMultiple]="config.isMultiple"
            [isClearable]="config.isClearable"
            [isDisabled]="config.isDisabled"
            [isRequired]="config.isRequired"
            [isEditMode]="config.isEditMode"
            [(value)]="value" />
    }
    @case (inputTypes.CODELIST) {
        <app-codelist-input
            [id]="config.id"
            [title]="config.title"
            [label]="config.label"
            [labelPosition]="config.labelPosition"
            [textField]="config.textField"
            [valueField]="config.valueField"
            [codelist]="config.codelist"
            [popupSettings]="config.popupSettings"
            [formatLabel]="config.formatLabel"
            [customFilter]="config.customFilter"
            [isMultiple]="config.isMultiple"
            [isDisabled]="config.isDisabled"
            [isRequired]="config.isRequired"
            [isEditMode]="config.isEditMode"
            [(value)]="value" />
    }
}
