<div
    class="relative"
    kendoTooltip
    position="top"
    tooltipClass="{{ error ? 'error-tooltip' : null }}"
    [tooltipTemplate]="error ? tooltipTemplate : null"
    [ngClass]="{ 'h-100': type === 'boolean' }">
    @if (label && type !== 'boolean') {
        <label class="flex select-none items-center justify-between text-xs font-normal text-neutral-400" [for]="label">
            {{ label | translate }}
            @if (isClicked && maxlength) {
                <span class="relative">{{ characterCount }} / {{ maxlength }}</span>
            }
        </label>
    }
    @if (isEditMode) {
        @switch (type) {
            @case ('string') {
                <kendo-textbox
                    class="bg-input text-input-foreground"
                    type="text"
                    kendoTextBox
                    title=""
                    autocomplete="off"
                    [placeholder]="placeholder"
                    [maxlength]="maxlength"
                    [disabled]="isDisabled"
                    [ngClass]="{
                        'is-invalid': !!error,
                        'brightness-75': isDisabled
                    }"
                    [(ngModel)]="value"
                    (focus)="focusInHandler()"
                    (blur)="focusOutHandler()"
                    (keypress)="onKeyPress($event)">
                    <ng-template kendoTextBoxSuffixTemplate>
                        <ng-content select=".textbox-suffix"></ng-content>
                    </ng-template>
                </kendo-textbox>
            }
            @case ('password') {
                <input
                    class="bg-input text-input-foreground"
                    type="password"
                    kendoTextBox
                    title=""
                    autocomplete="off"
                    [placeholder]="placeholder || ''"
                    [ngClass]="{
                        'is-invalid': !!error,
                        'brightness-75': isDisabled
                    }"
                    [attr.readonly]="isDisabled ? '' : null"
                    [(ngModel)]="value" />
            }
            @case ('textarea') {
                <kendo-textarea
                    class="bg-input text-input-foreground"
                    #textarea
                    title=""
                    [attr.readonly]="isDisabled ? '' : null"
                    [maxlength]="maxlength"
                    [disabled]="isDisabled"
                    [ngClass]="{ 'is-invalid': !!error, 'brightness-75': isDisabled }"
                    [rows]="rows"
                    [(ngModel)]="value"
                    (focus)="focusInHandler()"
                    (blur)="focusOutHandler()"></kendo-textarea>
            }
            @case ('number') {
                <kendo-numerictextbox
                    class="bg-input text-input-foreground"
                    title=""
                    [maxlength]="maxlength"
                    [format]="format || '#'"
                    [min]="min || 0"
                    [max]="max"
                    [autoCorrect]="true"
                    [disabled]="isDisabled"
                    [ngClass]="{ 'is-invalid': !!error, 'brightness-75': isDisabled }"
                    [decimals]="decimals || 2"
                    [readonly]="isDisabled"
                    [(ngModel)]="value"
                    (focus)="focusInHandler()"
                    (blur)="focusOutHandler()"></kendo-numerictextbox>
            }
            @case ('boolean') {
                <div class="checkbox">
                    <input
                        type="checkbox"
                        kendoCheckBox
                        #checkbox
                        [id]="label"
                        [disabled]="isDisabled"
                        [(ngModel)]="value" />
                    <label
                        *ngIf="label"
                        class="select-none pl-1 text-xs font-normal text-muted hover:cursor-pointer"
                        [for]="label"
                        (click)="toggleCheckbox(checkbox)">
                        {{ label | translate }}
                    </label>
                </div>
            }
            @case ('date') {
                <div>
                    @if (required !== true && dateValue && !isDisabled) {
                        <span
                            class="absolute right-9 top-[27px] z-10 cursor-pointer italic text-input-foreground"
                            title="{{ 'Clear' | translate }}"
                            (click)="dateValue = null">
                            <ng-icon name="faSolidXmark" />
                        </span>
                    }
                    <kendo-datepicker
                        class="bg-input text-input-foreground"
                        #datePicker
                        placeholder=""
                        title=""
                        format="{{ defaultDateFormat }}"
                        [navigation]="false"
                        [readonly]="isDisabled"
                        [ngClass]="{ 'is-invalid': !!error, 'brightness-75': isDisabled }"
                        [disabled]="isDisabled"
                        [disabledDates]="disabledDates"
                        [(ngModel)]="dateValue"
                        (focus)="focusInHandler()"
                        (blur)="focusOutHandler()">
                        <kendo-datetimepicker-messages
                            today="{{ 'Today' | translate }}"
                            dateTab="{{ 'Date' | translate }}"
                            timeTab="{{ 'Time' | translate }}"
                            now="{{ 'Now' | translate }}"
                            accept="{{ 'Accept' | translate }}"
                            cancel="{{ 'Cancel' | translate }}"></kendo-datetimepicker-messages>
                    </kendo-datepicker>
                </div>
            }
            @case ('datetime') {
                <div>
                    @if (required !== true && dateValue && !isDisabled) {
                        <span
                            class="absolute right-9 top-[27px] z-10 cursor-pointer italic text-input-foreground"
                            title="{{ 'Clear' | translate }}"
                            (click)="dateValue = null">
                            <ng-icon name="faSolidXmark" />
                        </span>
                    }
                    <kendo-datetimepicker
                        class="bg-input text-input-foreground"
                        #dateTimePicker
                        placeholder=""
                        format="{{ defaultDateTimeFormat }}"
                        title=""
                        [readonly]="isDisabled"
                        [ngClass]="{ 'is-invalid': !!error, '': isDisabled }"
                        [disabled]="isDisabled"
                        [disabledDates]="disabledDates"
                        [(ngModel)]="dateValue"
                        (focus)="focusInHandler()"
                        (blur)="focusOutHandler()">
                        <kendo-datetimepicker-messages
                            today="{{ 'Today' | translate }}"
                            dateTab="{{ 'Date' | translate }}"
                            timeTab="{{ 'Time' | translate }}"
                            now="{{ 'Now' | translate }}"
                            accept="{{ 'Accept' | translate }}"
                            cancel="{{ 'Cancel' | translate }}"></kendo-datetimepicker-messages>
                    </kendo-datetimepicker>
                </div>
            }
            @case ('yesno') {
                <kendo-combobox
                    class="bg-input text-input-foreground"
                    textField="text"
                    valueField="value"
                    title=""
                    [valuePrimitive]="true"
                    [data]="filteredOptions"
                    [filterable]="false"
                    [disabled]="isDisabled"
                    [clearButton]="required !== true"
                    [ngClass]="{ 'is-invalid': !!error, 'brightness-75': isDisabled }"
                    [(ngModel)]="value"></kendo-combobox>
            }
            @case ('file') {
                <kendo-fileselect
                    title=""
                    [ngClass]="{ 'is-invalid': !!error, disabled: isDisabled }"
                    [multiple]="multi"
                    [disabled]="isDisabled"
                    [restrictions]="fileRestrictions"
                    [(ngModel)]="value"
                    (select)="fileSelect($event)">
                    <kendo-upload-messages
                        [select]="
                            multi ? ('Select files...' | translate) : ('Select file...' | translate)
                        "></kendo-upload-messages>
                </kendo-fileselect>
            }
        }
        <!-- we use @if because @switch does not support case with multiple values yet -->
        @if (type === 'codelist') {
            @if (multi) {
                <div *subscribe="query$ as query">
                    <kendo-multiselect
                        class="bg-input text-input-foreground"
                        #multiselect
                        [textField]="textField"
                        [valueField]="valueField"
                        [valuePrimitive]="true"
                        [data]="filteredOptions"
                        [filterable]="isFilterable"
                        [loading]="query?.isLoading"
                        [readonly]="isDisabled || query?.isLoading"
                        [popupSettings]="popupSettings"
                        [clearButton]="clearButton"
                        [disabled]="isDisabled"
                        [ngClass]="{
                            'is-invalid': !!error,
                            'brightness-75': isDisabled || query?.isLoading,
                            required: required
                        }"
                        [(ngModel)]="value"
                        (mouseenter)="isMouseEnter = true"
                        (mouseleave)="isMouseEnter = false"
                        (focus)="focusInHandler()"
                        (blur)="focusOutHandler()"
                        (open)="onDropdownOpen()"
                        (filterChange)="filterCodeList($event)"
                        (ngModelChange)="ngModelChange.emit($event)">
                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                            <div class="p-1">
                                <label class="k-checkbox-label">{{ dataItem.label | translate }}</label>
                            </div>
                        </ng-template>
                    </kendo-multiselect>
                    @if (!(query?.isLoading || isMouseEnter) && value?.length > 0) {
                        <span
                            class="absolute right-1 top-1 text-input-foreground"
                            [ngClass]="{ 'right-6': !required && !isDisabled }">
                            ({{ value.length }})
                        </span>
                    }
                </div>
            } @else {
                <kendo-combobox
                    class="bg-input text-input-foreground"
                    title=""
                    data-container="body"
                    *subscribe="query$ as query"
                    [textField]="textField"
                    [valueField]="valueField"
                    [valuePrimitive]="true"
                    [data]="filteredOptions"
                    [filterable]="isFilterable"
                    [disabled]="isDisabled"
                    [readonly]="isDisabled || query?.isLoading"
                    [clearButton]="clearButton"
                    [loading]="query?.isLoading"
                    [popupSettings]="popupSettings"
                    [ngClass]="{
                        'is-invalid': !!error,
                        'brightness-75': isDisabled || query?.isLoading,
                        isLoading: query?.isLoading
                    }"
                    [(ngModel)]="value"
                    (focus)="focusInHandler()"
                    (blur)="focusOutHandler()"
                    (open)="onDropdownOpen()"
                    (filterChange)="filterCodeList($event)"></kendo-combobox>
            }
        }
        @if (type === 'select') {
            <kendo-combobox
                class="bg-input text-input-foreground"
                title=""
                data-container="body"
                [textField]="textField"
                [valueField]="valueField"
                [valuePrimitive]="true"
                [disabled]="isDisabled"
                [data]="options"
                [value]="value"
                [clearButton]="clearButton"
                [filterable]="isFilterable"
                [readonly]="isDisabled"
                [popupSettings]="popupSettings"
                [ngClass]="{
                    'is-invalid': !!error,
                    'brightness-75': isDisabled
                }"
                [(ngModel)]="value"
                (valueChange)="valueChange.emit($event)"
                (filterChange)="filterChange.emit($event)" />
        }
    } @else {
        <div class="pb-1 pt-1.5">
            @if (value && type !== 'yesno') {
                @switch (type) {
                    @case ('select') {
                        <span title="{{ getTooltip(value) }}">{{ selectViewValue }}</span>
                    }
                    @case ('codelist') {
                        @if (value | codelist: codelist | async; as codelistItem) {
                            @if (customRoute) {
                                <app-link [route]="[customRoute.path, customRoute.field]">
                                    {{ codelistItem }}
                                </app-link>
                            } @else {
                                <span
                                    class="-mt-1.5 block w-full rounded-sm border px-2 py-1"
                                    title="{{ getTooltip(value) }}">
                                    {{ codelistItem }}
                                </span>
                            }
                        }
                    }
                    @case ('number') {
                        @if (!decimals) {
                            {{ value | number: '1.3-3' }}
                        } @else {
                            {{ value | number: decimalFormat }}
                        }
                    }
                    @case ('datetime') {
                        {{ value | datetime }}
                    }
                    @case ('date') {
                        {{ value | customDate }}
                    }
                    @case ('boolean') {
                        <label class="block select-none pb-1 text-xs font-normal text-muted" [for]="label">
                            {{ label | translate }}
                        </label>
                        @if (value) {
                            <ng-icon class="text-success" name="faSolidCheck" />
                        } @else {
                            <ng-icon class="text-danger" name="faSolidXmark" />
                        }
                    }
                    @default {
                        {{ value }}
                    }
                }
            } @else if (type === 'yesno') {
                @if (value === true) {
                    <ng-icon class="text-success" name="faSolidCheck" />
                } @else if (value === false) {
                    <ng-icon class="text-danger" name="faSolidXmark" />
                } @else {
                    -
                }
            } @else {
                -
            }
        </div>
    }
</div>

<ng-template #tooltipTemplate>
    <span>{{ error | translate }}</span>
</ng-template>
