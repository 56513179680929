import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PillDirective } from '@common/directives/pill.directive';
import { bootstrapLockFill, bootstrapUnlockFill } from '@ng-icons/bootstrap-icons';
import { NgIconsModule, provideIcons } from '@ng-icons/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { Bollard, VesselPlan } from '../../neo-scheduler.interface';
import { MetadataComponent } from './metadata/metadata.component';

@Component({
    selector: 'app-vessel',
    standalone: true,
    imports: [CommonModule, NgIconsModule, ButtonsModule, PillDirective, MetadataComponent],
    providers: [provideIcons({ bootstrapLockFill, bootstrapUnlockFill })],
    templateUrl: './vessel.component.html',
    styleUrl: './vessel.component.scss'
})
export class VesselComponent implements OnInit, OnChanges {
    @Input({ required: true }) vessel!: VesselPlan;
    @Output() vesselChange = new EventEmitter<VesselPlan>();
    @Input({ required: true }) timeSlotWidth: number; // Width of each time slot in pixels
    @Input({ required: true }) bollards!: Bollard[];
    @Input({ required: true }) startDate!: Date; // Start date of the schedule
    @Input({ required: true }) timeSlotMinutes!: number;
    @Input({ required: true }) rowScale!: number;

    vesselStyle: { [key: string]: string } = {};

    ngOnInit() {
        this.positionVessel();
    }

    ngOnChanges() {
        this.positionVessel();
    }

    private resetVesselPosition() {
        // Find the vessel element
        const vesselElement = document.querySelector(
            `[data-vessel-id="${this.vessel.vessel_announcement_id}"]`
        ) as HTMLElement;
        if (vesselElement) {
            // Remove data-x and data-y attributes. InteractJS uses them to position the element
            vesselElement.removeAttribute('data-x');
            vesselElement.removeAttribute('data-y');
            // Directly reset the transform on the element
            vesselElement.style.transform = 'translate(0px, 0px)';
        }
    }

    private positionVessel() {
        this.resetVesselPosition();

        const vesselStartTimeOffsetFromViewStartTime =
            this.vessel.planned_dock_date.getTime() - this.startDate.getTime();

        const left =
            this.getTimeSlotIndex(vesselStartTimeOffsetFromViewStartTime, this.timeSlotMinutes) * this.timeSlotWidth;
        const top = this.calculateTopPosition(this.vessel.planned_bollard_start);
        const width = (this.vessel.operation_time / this.timeSlotMinutes) * this.timeSlotWidth;
        const height = this.vessel.loa * this.rowScale;

        this.vesselStyle = {
            left: `${left}px`,
            top: `${top}px`,
            width: `${width}px`,
            height: `${height}px`
        };
    }

    private getTimeSlotIndex(timeOffsetMilliseconds: number, timeSlotMinutes: number) {
        return Math.floor(timeOffsetMilliseconds / (timeSlotMinutes * 60 * 1000));
    }

    private calculateTopPosition(bollardIndex: number) {
        return this.bollards.reduce((acc, bollard, index) => {
            if (index < bollardIndex) {
                return acc + (bollard.rowHeight || 0);
            }
            return acc;
        }, 0);
    }
}
