<app-input-label [label]="label" [labelPosition]="labelPosition" [for]="id" [isRequired]="isRequired">
    @if (isEditMode) {
        <kendo-textarea
            [id]="id"
            [title]="title | translate"
            [placeholder]="placeholder"
            [maxlength]="maxLength"
            [disabled]="isDisabled"
            [required]="isRequired"
            [ngClass]="generateInputStyle()"
            [rows]="rows"
            [(ngModel)]="value" />
    } @else {
        <div class="pb-1 pt-1.5">{{ value() }}</div>
    }
</app-input-label>
