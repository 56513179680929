import { Routes } from '@angular/router';
import { UnauthorizedComponent } from '@common/components/unauthorized.component';
import { LoginComponent } from './common/components/login/login.component';
import { LogoutComponent } from './common/components/logout.component';
import { AuthGuard } from './common/guards/auth.guard';
import { HomeComponent } from './home/home.component';
import { UserAccountModule } from './user-account/user-account.module';

export const ROUTES: Routes = [
    { path: 'login', component: LoginComponent },
    { path: '401', component: UnauthorizedComponent },
    { path: 'logout', component: LogoutComponent },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: '/home', pathMatch: 'full' },
            {
                path: 'administration',
                loadChildren: () =>
                    import('./administration/administration.module').then((m) => m.AdministrationModule),
                data: { title: 'Administration' }
            },
            { path: 'home', component: HomeComponent },
            {
                path: 'vessel',
                loadChildren: () => import('./vessel/vessel.module').then((m) => m.VesselModule),
                data: { title: 'Vessels' }
            },
            {
                path: 'vessel-announcement',
                loadChildren: () =>
                    import('./vessel-announcement/vessel-announcement.module').then((m) => m.VesselAnnouncementModule),
                data: { title: 'Vessel Announcements' }
            },
            {
                path: 'berth',
                loadChildren: () => import('./berth/berth.module').then((m) => m.BerthModule),
                data: { title: 'Berths' }
            },
            {
                path: 'berth-plan',
                loadChildren: () => import('./berth-plan/berth-plan.module').then((m) => m.BerthPlanModule),
                data: { title: 'Berthing Plans' }
            },
            {
                path: 'user-account',
                loadChildren: () => UserAccountModule,
                data: { title: 'Account' }
            },
            { path: '**', redirectTo: '/home' }
        ]
    }
];
