import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { environment } from '@environments/environment';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-upload-multiple-files',
    template: `
        <app-editable-grid
            entityName="Attachment"
            [data]="files"
            [editMode]="editMode"
            [autoFitColumns]="false"
            (rowAction)="rowAction($event)">
            <kendo-grid-column field="name" title="{{ 'Name' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span style="color: #007bff; cursor: pointer" (click)="download(dataItem)">
                        {{ dataItem.name }}
                    </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="typeId" title="{{ 'Type' | translate }}">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control type="codelist" codelist="AttachmentType" [(ngModel)]="dataItem.typeId" />
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="dataItem.typeId | codelist: 'AttachmentType' | async as codelist">
                        {{ codelist }}
                    </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="createdById" title="{{ 'Created By' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.createdById | codelist: 'User' | async }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
                field="createdDate"
                format="g"
                title="{{ 'Created Date' | translate }}"></kendo-grid-column>
            <kendo-grid-column field="lastModifiedById" title="{{ 'Last Modified By' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.lastModifiedById | codelist: 'User' | async }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
                field="lastModifiedDate"
                format="g"
                [title]="'Last Modified Date' | translate"></kendo-grid-column>
            <kendo-grid-column field="internal" title="{{ 'Internal' | translate }}">
                <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                    <app-control type="boolean" [(ngModel)]="dataItem.internal" />
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <app-control type="boolean" [isDisabled]="true" [(ngModel)]="dataItem.internal" />
                </ng-template>
            </kendo-grid-column>
        </app-editable-grid>
    `
})
export class UploadMultipleFilesComponent {
    @Input() files: any[];
    @Input() disabled: boolean;
    @Input() isInternalDisabled;
    @Input() allowToEditFile = (file) => true;
    @Input() allowToDeleteFile = (file) => true;
    @Input() editMode: any;
    @Output() public removeFile: EventEmitter<any> = new EventEmitter<any>();
    @Output() public add: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: HttpClient) {}

    removeAction(file) {
        if (!this.allowToDeleteFile(file)) return;
        this.removeFile.emit(file);
    }

    rowAction(event): void {
        if (event?.action === 'add') this.add.emit();
        if (event?.action === 'remove') this.removeAction(event);
        if (event?.action === 'cancel') this.removeAction(event);
    }

    async download(file) {
        if (!this.canDownload(file)) {
            return;
        }

        if (file.id > 0) {
            const attachment: any = await firstValueFrom(
                this.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${file.id}`)
            );
            UploadActionButtonsComponent.preview(attachment.name, attachment.content);
        } else {
            UploadActionButtonsComponent.preview(file.name, file.content);
        }
    }

    canDownload(file) {
        return file && (file.id > 0 || !!file.content);
    }

    isDisabled(file) {
        return this.disabled || !this.allowToEditFile(file);
    }
}
