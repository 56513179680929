import { HttpClient } from '@angular/common/http';
import { inject, Injectable, Injector } from '@angular/core';
import { PaginatedQuery } from '@common/interfaces/query.interface';
import { QueryService } from '@common/services/query.service';
import { environment } from '@environments/environment';
import { Bollard, VesselForPlanning, VesselPlan } from 'app/neo-scheduler/neo-scheduler.interface';
import { map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BerthPlanService {
    // private apiUrl = 'https://ai-berthing.portline.eu/optimize';
    private apiUrl = environment.plannerUrl;
    http = inject(HttpClient);
    query = inject(QueryService);

    optimizeSchedule(
        combinedVesselData: (DemoVesselVisit | VesselAnnouncement)[],
        allBollards: Bollard[],
        planningFrom: Date
    ): Observable<VesselPlan[]> {
        const vesselVisits = this.mapCombinedDataToVesselsForPlanning(combinedVesselData);
        const payload = {
            vessel_visits: vesselVisits.map((visit) => ({
                ...visit,
                eta: visit.eta.toISOString(),
                etd: visit.etd.toISOString(),
                planned_dock_date: visit.planned_dock_date?.toISOString(),
                planned_undock_date: visit.planned_undock_date?.toISOString()
            })),
            port: {
                Bollards: allBollards
            },
            parameters: {
                st_iteracij: 10,
                to_be_safe: 200,
                min_cas: planningFrom.toISOString()
            }
        };

        return this.http.post<VesselPlan[]>(this.apiUrl, payload).pipe(
            map((response) =>
                response.map((visit) => ({
                    ...visit,
                    planned_dock_date: new Date(visit.planned_dock_date),
                    planned_undock_date: new Date(visit.planned_undock_date)
                }))
            )
        );
    }

    /**
     * Merges vessel announcements and visits into one.
     *
     * @param announcements
     * @param visits
     * @returns a combined array of vessel announcements and visits
     */
    mergeAnnouncementsAndVisits(
        announcements: VesselAnnouncement[],
        visits: DemoVesselVisit[]
    ): (DemoVesselVisit | VesselAnnouncement)[] {
        const combined = [
            ...announcements.map((a) => ({ ...a, locked: false })),
            ...visits.map((v) => ({ ...v, locked: true, id: v.vesselAnnouncementId }))
        ];
        return combined;
    }

    resetApplicationState() {
        return this.query.getCommandMutation().mutate({
            command: 'ResetBerthingState',
            data: {}
        });
    }

    activatePlan(id: number) {
        return this.query.getCommandMutation().mutate({
            command: 'ActivateBerthingPlan',
            data: {
                id
            }
        });
    }

    private clearPlannedProperties(visit: DemoVesselVisit): DemoVesselVisit {
        return {
            ...visit,
            dockTime: undefined,
            undockTime: undefined,
            fromBollardSequentialId: undefined,
            toBollardSequentialId: undefined
        };
    }

    private mapCombinedDataToVesselsForPlanning(
        combinedData: (DemoVesselVisit | VesselAnnouncement)[]
    ): VesselForPlanning[] {
        return combinedData.map((item) => {
            if (!item.locked) {
                item = this.clearPlannedProperties(item as DemoVesselVisit);
            }
            return {
                vessel_announcement_id: item.id,
                imo: item.imo,
                cargo_type: item.terminalId,
                draft: item.draft,
                eta: item.eta,
                etd: item.etd,
                loa: item.loa,
                n_bollards:
                    'toBollardSequentialId' in item && 'fromBollardSequentialId' in item
                        ? item.toBollardSequentialId - item.fromBollardSequentialId + 1
                        : 0,
                operation_time: (item.etd.getTime() - item.eta.getTime()) / (60 * 1000),
                priority: item.priority,
                hazmat: item.hasHazmat,
                planned_bollard_start: 'fromBollardSequentialId' in item ? item.fromBollardSequentialId : undefined,
                planned_dock_date: 'dockTime' in item ? item.dockTime : undefined,
                planned_undock_date: 'undockTime' in item ? item.undockTime : undefined
            };
        });
    }

    fetchVesselAnnouncements(from: Date, to: Date, injector?: Injector) {
        return this.query.getQuery<PaginatedQuery<VesselAnnouncement> | undefined>(
            'VesselAnnouncements',
            {
                orderBy: 'eta',
                orderDirection: 'desc',
                from,
                to
            },
            { injector }
        ).result;
    }

    fetchVesselAnnouncementsRegular({ etaTo, statusId }: { etaTo: Date; statusId: string }) {
        return this.http
            .post<PaginatedQuery<VesselAnnouncement>>(`${environment.apiUrl}/query/VesselAnnouncements`, {
                orderBy: 'eta',
                orderDirection: 'desc',
                etaTo, // TODO: this will fetch ALL vessel from the past. For Demo amount of vessels it's fine :)
                statusId
            })
            .pipe(
                map((response) => ({
                    ...response,
                    results: response.results.map(this.convertAnnouncementDatesToJS)
                }))
            );
    }

    fetchVesselVisitsMap(date: Date, injector?: Injector) {
        return this.query.getQuery<PaginatedQuery<DemoVesselVisit> | undefined>(
            'DemoVesselVisitsMap',
            {
                date: date,
                active: true
            },
            { injector }
        ).result;
    }

    fetchVesselVisitsRegular({ etdFrom, active }: { etdFrom: Date; active: boolean }) {
        return this.http
            .post<PaginatedQuery<DemoVesselVisit>>(`${environment.apiUrl}/query/DemoVesselVisits`, {
                orderBy: '',
                orderDirection: 'desc',
                etdFrom,
                active
            })
            .pipe(
                map((response) => ({
                    ...response,
                    results: response.results.map(this.convertVisitDatesToJS)
                }))
            );
    }

    async editVesselAnnouncement(item: VesselAnnouncementPartialEdit) {
        return this.query.getCommandMutation().mutateAsync({
            command: `editVesselAnnouncement`,
            data: { ...item },
            invalidate: `VesselAnnouncements`
        });
    }

    private convertAnnouncementDatesToJS(item: VesselAnnouncement): VesselAnnouncement {
        return {
            ...item,
            eta: item.eta ? new Date(item.eta) : null,
            etd: item.etd ? new Date(item.etd) : null
        };
    }

    /**
     * Calculates the final bollard needed to berth the vessel, given the starting bollard and the vessel length
     *
     * TODO: Fix... This is NOT robust but should work for DEMO purposes
     * @param vv - The vessel visit
     * @param bollards - The flattened bollards
     * @returns The bollard to
     */
    calculateBollardTo(vv: DemoVesselVisit, bollards: Bollard[]): number {
        let vesselLength = vv.loa;
        const bollardTo = bollards.slice(vv.fromBollardSequentialId + 1).find((b) => {
            vesselLength -= b.gap_to_previous;
            return vesselLength <= 0;
        });
        return bollardTo ? bollardTo.bollard_id : -1;
    }

    convertVisitDatesToJS(item: DemoVesselVisit): DemoVesselVisit {
        return {
            ...item,
            eta: item.eta ? new Date(item.eta) : null,
            etd: item.etd ? new Date(item.etd) : null,
            etu: item.etu ? new Date(item.etu) : undefined,
            etb: item.etb ? new Date(item.etb) : undefined,
            dockTime: item.dockTime ? new Date(item.dockTime) : undefined,
            undockTime: item.undockTime ? new Date(item.undockTime) : undefined
        };
    }

    roundToNearestHalfHour(date: Date): Date {
        const roundedDate = new Date(date);
        roundedDate.setMinutes(Math.round(date.getMinutes() / 30) * 30);
        roundedDate.setSeconds(0);
        roundedDate.setMilliseconds(0);
        return roundedDate;
    }
}

export interface DemoVesselVisit extends VesselAnnouncement {
    dockTime?: Date;
    undockTime?: Date;
    fromBollardSequentialId: number;
    toBollardSequentialId: number;
    vesselAnnouncementId: number;
    vesselVisitStatusId: string;
    berthingPlanId: number;
    id: number;
    etu?: Date;
    etb?: Date;
    locked?: boolean;
}

export interface VesselAnnouncement {
    loa: number;
    draft: number;
    eta: Date | null;
    etd: Date | null;
    imo: string | null;
    name: string | null;
    cargo: string | null;
    cargoWeight: number;
    terminalId: string | null;
    demoVesselId: string | null;
    portOfCallId: string | null;
    previousPortOfCallId: string | null;
    nextPortOfCallId: string | null;
    statusId: string | null;
    id: number;
    locked?: boolean;
    bg_color?: string;
    hasHazmat: boolean;
    priority: number;
}

interface VesselAnnouncementPartialEdit {
    id: number;
    draft?: number;
    eta?: Date;
    etd?: Date;
    hasHazmat?: boolean;
    priority?: number;
}

export interface BerthPlan {
    startTime: string;
    endTime: string;
    active: boolean;
    vesselVisits: NewDemoVesselVisit[];
}

export interface NewDemoVesselVisit {
    vesselAnnouncementId: number;
    vesselVisitStatusId: string;
    dockTime: Date;
    undockTime: Date;
    fromBollardSequentialId: number;
    toBollardSequentialId: number;
}
