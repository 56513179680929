<div class="action-bar-container flex flex-col pt-1">
    <div class="flex flex-wrap gap-2">
        @for (group of groups; track $index) {
            @if ($index !== 0) {
                <div class="faded-separator w-0.5 self-stretch"></div>
            }
            <div class="flex flex-col items-center">
                <div class="flex gap-2">
                    @if (collapsed) {
                        <app-abdropdown-menu [label]="group.label" [items]="group.items"></app-abdropdown-menu>
                    } @else {
                        @for (item of group.items; track $index) {
                            <app-button
                                class="h-7"
                                size="md"
                                [variant]="item.variant"
                                [icon]="item.icon"
                                [iconOnly]="item.iconOnly"
                                [tooltip]="item.iconOnly ? item.label : null"
                                [disabled]="item.isDisabled"
                                (clicked)="item.onClick()">
                                {{ item.label | translate }}
                            </app-button>
                        }
                    }
                </div>
            </div>
        }
    </div>
</div>
