<div
    class="flex w-min items-center justify-between p-2"
    [ngClass]="{ 'gap-2': size === 'sm', 'gap-4': size === 'md', 'gap-8': size === 'lg' }">
    @for (item of items; track $index) {
        <button
            class="flex min-h-10 min-w-24 items-center justify-center gap-2 rounded-md border-2 bg-card transition"
            [ngClass]="{
                'px-2': size === 'sm',
                'px-3 py-1': size === 'md',
                'px-4 py-2': size === 'lg',
                'border-primary hover:!border-primary/100': activeIndex === $index && !item.isDisabled,
                'border-primary/10': activeIndex !== $index,
                'border-muted/10': item.isDisabled,
                'hover:border-primary/50': !item.isDisabled
            }"
            [disabled]="item.isDisabled"
            (click)="toggle($index)">
            @if (item.icon) {
                <ng-icon
                    class="transition"
                    [ngClass]="{
                        'text-lg': size === 'md',
                        'text-xl': size === 'lg',
                        'text-primary': activeIndex === $index && !item.isDisabled,
                        'text-muted': !item.isDisabled,
                        'text-muted/50': item.isDisabled
                    }"
                    [name]="item.icon" />
            }
            @if (item.label) {
                <div
                    class="whitespace-nowrap"
                    [ngClass]="{
                        'text-xs': size === 'sm',
                        'text-lg': size === 'lg',
                        'text-muted/60': item.isDisabled
                    }">
                    {{ item.label | translate }}
                </div>
            }
            @if (item.count) {
                <div
                    class="text-lg font-bold"
                    [ngClass]="{
                        'text-lg': size === 'md',
                        'text-xl': size === 'lg',
                        'text-muted/60': item.isDisabled
                    }">
                    {{ item.count }}
                </div>
            }
        </button>
    }
</div>
