import _ from 'lodash';
import { environment as base } from './environment.base';

const baseEnv = _.cloneDeep(base);

export const environment = _.merge(baseEnv, {
    name: 'development',
    plannerUrl: `${window.location.protocol}//${window.location.hostname}/optimize`,

    settings: {
        header: {
            environmentColor: 'color: #2cda43'
        }
    }
});
