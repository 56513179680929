<div class="vessels-container">
    @for (vessel of vessels; track vessel.imo) {
        <app-vessel
            [startDate]="startDate"
            [timeSlotMinutes]="timeSlotMinutes"
            [rowScale]="rowScale"
            [bollards]="allBollards()"
            [timeSlotWidth]="timeSlotWidth"
            [vessel]="vessel"
            (vesselChange)="onVesselChange($event)"></app-vessel>
    }
</div>
