<app-input-label [label]="label" [labelPosition]="labelPosition" [for]="id" [isRequired]="isRequired">
    @if (isEditMode) {
        <kendo-numerictextbox
            [ngClass]="generateInputStyle()"
            [id]="id"
            [title]="title | translate"
            [maxlength]="maxLength"
            [format]="format || '#'"
            [min]="min"
            [max]="max"
            [autoCorrect]="true"
            [disabled]="isDisabled"
            [decimals]="decimals"
            [readonly]="isDisabled"
            [(ngModel)]="value"
            (valueChange)="onValueChange($event)" />
        <app-input-error [error]="error()?.[0]" />
    } @else {
        <div class="pb-1 pt-1.5">{{ value() }}</div>
    }
</app-input-label>
