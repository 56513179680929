<app-input-label [label]="label" [labelPosition]="labelPosition" [for]="id" [isRequired]="isRequired">
    @if (isEditMode) {
        <kendo-datepicker
            [ngClass]="generateInputStyle()"
            [id]="id"
            [title]="title | translate"
            [placeholder]="placeholder"
            [format]="format"
            [navigation]="false"
            [required]="isRequired"
            [max]="maxDate"
            [min]="minDate"
            [disabled]="isDisabled"
            [disabledDates]="disabledDates"
            [(ngModel)]="value"
            (valueChange)="onValueChange($event)">
            <kendo-datetimepicker-messages
                [today]="'Today' | translate"
                [dateTab]="'Date' | translate"
                [timeTab]="'Time' | translate"
                [now]="'Now' | translate"
                [accept]="'Accept' | translate"
                [cancel]="'Cancel' | translate" />
        </kendo-datepicker>
        <app-input-error [error]="error()?.[0]" />
    } @else {
        <div class="pb-1 pt-1.5">
            {{ value() | date: format }}
        </div>
    }
</app-input-label>
