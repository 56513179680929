const terminalColors = {
    EET: '#ffcdd2', // Red 100
    GT: '#c8e6c9', // Green 100
    KT: '#ffe0b2', // Orange 100
    OPP: '#b2ebf2', // Cyan 100
    PET: '#f5f5f5', // Grey 100
    POT: '#bbdefb', // Blue 100
    TA: '#f8bbd0', // Pink 100
    TL: '#d7ccc8', // Brown 100
    TS: '#fff9c4', // Yellow 100
    TST: '#e1bee7', // Purple 100
    TTT: '#d1c4e9', // Deep Purple 100
    TZ: '#dcedc8' // Light Green 100
};

export default terminalColors;
