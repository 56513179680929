import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-upload-single-file',
    template: `
        <div class="k-file-single input-group input-group-sm">
            <input class="form-control" type="text" [disabled]="true" [value]="fileName" />
            <app-upload-action-buttons
                class="input-group-append"
                [file]="file"
                [disabled]="disabled"
                (removeFile)="onRemoveFile($event)" />
        </div>
    `
})
export class UploadSingleFileComponent {
    @Input() file: any;
    @Input() disabled: boolean;

    @Output() public removeFile = new EventEmitter<any>();

    get fileName() {
        return this.file ? this.file.name : null;
    }

    onRemoveFile(file) {
        this.removeFile.emit(file);
    }
}
