<kendo-panelbar class="rounded-md border-none p-2" (stateChange)="stateChange($event)">
    <kendo-panelbar-item [expanded]="expanded">
        <ng-template kendoPanelBarItemTitle>
            <div *ngIf="!expanded && hasMetaFilter; else titleOnly">
                <ng-content select="[meta-filter]"></ng-content>
            </div>
            <ng-template #titleOnly>
                <div class="flex items-center justify-center gap-1 text-lg font-normal">
                    <ng-icon class="mr-1" name="faSolidFilter" />
                    {{ 'Filters' | translate }}
                </div>
            </ng-template>
        </ng-template>
        <ng-template kendoPanelBarContent>
            <div class="p-4">
                <ng-content select="[all-filter]"></ng-content>
                <div class="mt-4 flex justify-end gap-2">
                    <app-button variant="secondary" icon="faSolidFilterCircleXmark" (clicked)="clearFilter.emit()">
                        {{ 'Clear' | translate }}
                    </app-button>
                    <app-button variant="primary" icon="faSolidMagnifyingGlass" (click)="search.emit()">
                        {{ 'Search' | translate }}
                    </app-button>
                </div>
            </div>
        </ng-template>
    </kendo-panelbar-item>
</kendo-panelbar>
