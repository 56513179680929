import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-footer',
    template: `
        <footer class="flex justify-between">
            <div class="flex gap-1">
                <strong id="copyright">
                    {{ 'Copyright' | translate }} © {{ year }}
                    <a href="http://www.actual.si/">Actual I.T.</a>
                </strong>
                <span>{{ 'All rights reserved' | translate }}.</span>
            </div>
            <div class="flex justify-end gap-1">
                <span translate>Version</span>
                <span class="font-bold">{{ version }}</span>
            </div>
        </footer>
    `,
    styles: `
        :host {
            font-size: 12px;
            background: #fff;
            color: #444;
            border-top: 1px solid #d2d6de;
            width: 100%;
            padding: 10px;
            transition:
                transform 0.3s ease-in-out,
                margin 0.3s ease-in-out;
        }

        :host.fw {
            width: 100%;
            margin-left: 0;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
    version = environment.version;
    year = new Date().getFullYear();
}
